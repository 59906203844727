


export default {

  onDevice: false,

  init () {
    var classNames = [];
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios');
    if (navigator.userAgent.match(/android/i)) classNames.push('device-android');

    var html = document.getElementsByTagName('html')[0];

    if (classNames.length) {
      classNames.push('on-device');
      this.onDevice = true;

      /* disabled, caused a bug on some devices

      const _app = this;
      window.addEventListener("resize", function() {
        _app.updateViewH();
      });
      */
    }
    if (html.classList && classNames.length) {
      html.classList.add.apply(html.classList, classNames);
    }
  },
  updateViewH () {
    /* disabled, caused a bug on some devices

    if(this.onDevice) {
      var el = document.querySelector(".view-h");
      if(el) {
        el.style.height = 'auto';
        setTimeout(function() {
          el.style.height = document.body.clientHeight + 'px';
        });
      }
    }*/
  }
};
