import Vue from "vue";
import EventBus from "@/services/EventBus";

export default new Vue({
  created() {
    let start = null;
    window.addEventListener("touchstart", function (event) {
      if (event.touches.length === 1) {
        //just one finger touched
        start = event.touches.item(0).clientX;
      } else {
        //a second finger hit the screen, abort the touch
        start = null;
      }
    });

    window.addEventListener("touchend", function (event) {

      let offset = 100;//at least 100px are a swipe
      if (start != null) {
        //the only finger that hit the screen left it
        let end = event.changedTouches.item(0).clientX;
        if (end > start + offset) {
          EventBus.$emit('swipe-right');
          start = null;
        }
        if (end < start - offset) {
          EventBus.$emit('swipe-left');
          start = null;
        }
      }
    });
  }
});