import {Storage} from "@capacitor/storage";

export default class CapacitorCache {

    async get(key) {
        console.log(`CapacitorCache get ${key}`)
        if(!key) {
            return null;
        }
        const result = await Storage.get({key: key});
        return JSON.parse(result.value);
    }

    async set(key, value) {
        if(!key) {
            return null;
        }
        console.log(`CapacitorCache set ${key}: ${JSON.stringify(value)}`)

        return Storage.set({key: key, value: JSON.stringify(value)})
    }

    async remove(key) {
        if(!key) {
            return null;
        }
        console.log(`CapacitorCache remove ${key}`)
        return Storage.remove({key: key});
    }

    async allKeys() {
        const keyResult = await Storage.keys();
        console.log(`CapacitorCache allKeys ${JSON.stringify(keyResult.keys)}`)
        return keyResult.keys;

    }
}
