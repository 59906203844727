<template>


    <div class="lottie-background">
      <div class="row">
        <div class="col-md-12 text-center question-info">
            <lottie-animation path="/lottie/mm-shapeshifter.json" :autoplay="true" :loop="true" id="mm-shapeshifter" class="mm-shapeshifter"></lottie-animation>

            <div class="slider-container">
              <div class="carousel">

                <div class="center-container-ie11fix">
                  <div class="center-container">
                    <div class="center-content">

                      <h2 v-html="data.text"></h2>
                      <hr />
                      <p v-html="data.description"></p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

        </div>
      </div>
    </div>

</template>

<script>

import LottieAnimation from "@/components/shared/LottieAnimation";

export default {
  name: "QuestionInfo",
  components: {LottieAnimation},
	props: {
    data: Object,
    errormsg: String
  },
  methods: {
    getValue() {
      return 0;
    }
  },
  data() {
    return {
      isValid: this.valid,
      internalValue: this.value
    };
  },
  mounted() {
    this.internalValue = this.value;
  }
};
</script>
