/*global gtag*/
// eslint-disable-next-line no-console
import FcmService from "./services/FcmService";
// required for IE11
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import DeviceHelper from './helpers/deviceHelper.js';
import Vue from 'vue'
import App from './App.vue'
/* -----------------------------------------------------------------
  * main app
  *
  * -------------------------------------------------------------- */
import Vuex, {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import store from '@/store';
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '@/router';
import BootstrapVue from 'bootstrap-vue';

import i18n from "@/services/I18n.js";
import Vuelidate from 'vuelidate';
import Swipe from "@/services/Swipe";
import EventBus from "@/services/EventBus";
import bFormSlider from 'vue-bootstrap-slider';
import VueNumeric from 'vue-numeric';
import FooterPartial from "@/components/shared/Footer";
import Moment from 'moment'

import {Storage} from "@capacitor/storage";
import {applyAuthTokenInterceptor} from "./auth/authInterceptor";

import './registerServiceWorker'

import {defineCustomElements} from '@ionic/pwa-elements/loader';
// Import the plugin here
import {Auth0Plugin} from "./auth";

import {routeGuard} from "./router/routeGuard";

console.log(`
Concept, ontwerp & realisatie:

██╗   ██╗███████╗███████╗██╗      █████╗ ██████╗ 
██║   ██║██╔════╝██╔════╝██║     ██╔══██╗██╔══██╗
██║   ██║███████╗█████╗  ██║     ███████║██████╔╝
██║   ██║╚════██║██╔══╝  ██║     ██╔══██║██╔══██╗
╚██████╔╝███████║███████╗███████╗██║  ██║██████╔╝
 ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝

 Uselab BV - Keizersgracht 205 - 1016 DS Amsterdam
 Phone +31(0)20 5309666 - Fax +31(0)20 5309667
 E-mail: info@uselab.com - http://www.uselab.com`);


DeviceHelper.init();

/**
 * include urlVars here so it's initialized. they will disappear after router is loaded!
 */

Vue.use(Swipe)
Vue.use(FooterPartial)
Vue.use(VueNumeric)
Vue.use(bFormSlider)
Vue.use(Vuelidate)
Vue.use(VueAxios, axios);
Vue.use(Vuex);
Vue.use(BootstrapVue);

Vue.prototype.$moment = Moment;
Vue.component('footer-partial', FooterPartial);

Vue.router = router;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const packageId = process.env.VUE_APP_AUTH0_PACKAGE_ID;
const audience = process.env.VUE_APP_AUTH0_AUDIENCE;
const scope = process.env.VUE_APP_AUTH0_SCOPE;



applyAuthTokenInterceptor(axios, {});

App.router = Vue.router;

Vue.use(Auth0Plugin, {
    domain,
    clientId,
    packageId,
    audience,
    scope,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    advancedOptions: {
        defaultScope: 'email'
    },
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});

/* -----------------------------------------------------------------
 * router interceptor
 *
 * -------------------------------------------------------------- */
var initialRouteTo = null; // save next route till auth is loaded
var prevRouteName = '';
App.router.beforeEach(routeGuard);

Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,
    data() {
        return {
            bgShape: false,
            initUser: true,
        }
    },
    computed: {
        ...mapState(["loading"]),
        ...mapGetters([
            "activeResearchId", 'getLocale'
        ])
    },
    methods: {
        ...mapActions(['getUser', 'getResearch', 'getSamplingsTodoToday']),
        ...mapMutations(['setAppFullyLoaded']),
    },
    async beforeCreate () {
        const locale = await Storage.get({ key: 'locale' });
        const header = {
            'nl': 'nl-NL',
            'en': 'en-EN',
            'fr': 'fr-FR'
        }

        i18n.locale = typeof locale === 'object' && locale.value ? locale.value : 'en';

        axios.interceptors.request.use((config) => {
            config.headers['Accept-Language'] = header[i18n.locale]
            return config;
        }, (error) => {
            return Promise.reject(error);
        });
    },
    created() {
        var app = this;

        this.$auth.$watch('loading', loading => {
            console.log('loading changed to ' + loading);
            if (loading === false) {
                if (app.$auth.isAuthenticated) {
                    app.getUser().then(userObj => {

                        app.$store.commit('user', userObj.data);

                        const fcmService = new FcmService()
                        fcmService.initPush(this.$index, this.$store)

                        // get activeResearchId from user object
                        let researchId = userObj.data.active_research_id;
                        app.$store.commit('activeResearchId', researchId);

                        // we need the research before we can continue
                        if (researchId) {
                            app.getResearch().then(response => {
                                app.$store.commit('research', response.data);
                                app.getSamplingsTodoToday().then(samplingsToDo => {
                                    app.$store.commit('setSamplingsToDo', samplingsToDo.data);
                                    app.$store.commit('loading', false);

                                    //continue with the app from here on
                                    app.setAppFullyLoaded(true);
                                });
                            }).catch(error => {
                                app.$store.commit('loading', false);
                                app.setAppFullyLoaded(true);
                                EventBus.$emit("on-server-error", error);
                            });
                        } else {
                            app.setAppFullyLoaded(true);
                        }

                    });
                } else {
                    console.log('not authenticated');
                    app.setAppFullyLoaded(true);
                }
            }
        });
    },
    render: h => h(App)
}).$mount('#app')

defineCustomElements(window);
