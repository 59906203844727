<template>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <b-form-group :class="{ 'form-group--error': !isValid }">
        <b-form-radio-group
          class="custom-controls-stacked"
          stacked
          v-model="internalValue"
          name="radio"
        >
          <b-form-radio
            @change="submitValue"
            v-for="item in options"
            :key="item.row"
            :value="item.option"
            v-if="item.enabled"
          >
            <span v-html="item.text"></span>
          </b-form-radio>
          <div v-if="textoption && textoption.enabled">
            <b-form-radio
              ref="checkboxExtraValue"
              class="form-control no-border"
              :value="textoption.option"
              >
              <span v-html="textoption.text"></span>
            </b-form-radio>
            <b-input
              v-show="showTextValue"
              @focus="scrollFix"
              class="form-control input-singleline"
              type="text"
              v-model="internalValueExtra"
              :maxlength="1024"
              id="collapse_input"
            />
          </div>
        </b-form-radio-group>
      </b-form-group>

      <p v-if="errormsg" class="invalid-feedback show text-center mb-1">
        {{ errormsg }}
      </p>
    </div>
  </div>
</template>

<script>
import EventBus from '@/services/EventBus'

export default {
  name: 'QuestionRadio',
  props: {
    options: Array,
    textoption: Object,
    valid: Boolean,
    value: [Number, String, Array],
    value_extra: String,
    errormsg: String
  },
  computed: {
    showTextValue() {
      if (this.textoption) {
        if (this.textoption.option == this.internalValue) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    getValue() {
      return this.internalValue
    },
    getValueExtra() {
      return this.showTextValue ? this.internalValueExtra : ''
    },
    submitValue() {
      //in order for this.internalValue to be updated first,
      // the submitValue event needs to emitted 1 tick later
      setTimeout(function () {
        EventBus.$emit('submit-value')
      })
    },
    scrollFix() {}
  },
  watch: {
    valid(v) {
      this.isValid = v
    },
    value(v) {
      this.internalValue = v
    },
    value_extra(v) {
      this.internalValueExtra = v
    }
  },
  data() {
    return {
      isValid: this.valid,
      internalValue: this.value,
      internalValueExtra: this.value_extra
    }
  },
  mounted() {
    this.internalValue = this.value
    this.internalValueExtra = this.value_extra

    // fix textfield position when keyboard in expanded
    EventBus.$on('keyboard-did-show', function () {
      document.getElementById('question-scroll-container').scrollTop = 99999
    })
  }
}
</script>
