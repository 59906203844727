import {getInstance} from "../auth";
import store from "../store";
import BackgroundGradientService from '../services/BackgroundGradientService'
import RouteHelper from '../services/RouteHelper'
import {Capacitor} from "@capacitor/core";

let initialRouteTo = null; // save next route till auth is loaded
let prevRouteName = '';
export const routeGuard = (to, from, next) => {
    const authService = getInstance();


    const fn = () => {

        //first check if we need to go to another route by force

        if (prevRouteName !== to.name) {
            prevRouteName = to.name;
            // if has auto routing
            if (to.meta.autoRoute) {
                let route = RouteHelper.getNextRoute();
                prevRouteName = route ? route.name : '';
                if (route) {
                    next(route);
                    return;
                }
            }
        }

        // now check if we need to be signed in for the given route
        if (to.meta.auth === true && !authService.isAuthenticated) {
            authService.loginWithRedirect({appState: {targetUrl: to.fullPath}});
            if (Capacitor.getPlatform() === 'web') {
                authService.loginWithRedirect({appState: {targetUrl: to.fullPath}});
            } else {
                authService.loginNative({appState: {targetUrl: to.fullPath}});
            }
            return;
        }

        //setup the environment based on meta information
        if (to.meta && to.meta.hideMenu) {
            store.commit("showNavigation", false);
        } else {
            store.commit("showNavigation", true);
        }

        if (to.meta && to.meta.bg) {
            BackgroundGradientService.setGradient(to.meta.bg);
        }

        if (to.meta) {
            BackgroundGradientService.setShape(!!to.meta.bgShape);
        }

        //continue to the route;=
        return next();

    };

    // If loading has already finished, execute the routeGuard
    if (store.getters.appFullyLoaded) {
        return fn();
    }

    // Watch for the app to be fully loaded an initialized before executing the routeGuard
    const unsubscribe = store.subscribe((mutation, state) => {
        if (mutation.type === 'setAppFullyLoaded' && mutation.payload === true) {
            unsubscribe();
            return fn();
        }
    });
};
