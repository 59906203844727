<template>
    <div>
        <div class="section">

            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3 content-min-height">

                        <h1>{{ $t(title) }}</h1>
                        <div>
                            <p>
                                Habital® provides online and mobile workplace insights, including but not limited to,
                                the Habital® website and mobile applications hosted by or on behalf of Habital® intended
                                to enhance participant’s usage and satisfaction about the work environment. Habital® is
                                committed to maintaining the privacy, integrity and security of any personal information
                                about it’s participants. This Privacy Statement explains how Measuremen protects
                                personal information Measuremen collects and how Measuremen uses that information.
                            </p>
                            <h2>Information Collection</h2>
                            <p>Habital® works in conjunction with a user account on Habital.com and Habital.io.
                                *When you sign up you are required to provide an email address (which will be used as
                                your login ID) and choose an account password.
                            </p>
                            <p>
                                *Information that derives from the participation in studies is stored in your Habital®
                                account.
                            </p>
                            <p>
                                Data is stored in compliance with EU-regulations.
                            </p>
                            <h2>How Measuremen Uses Your Information</h2>
                            <p>Habital® uses your personal information to:
                                *Provide you with the Service;
                            </p>
                            <p>
                                *Analyze Site usage and improve the Service;
                            </p>
                            <p>
                                *Deliver to you any administrative notices and communications relevant to your use of
                                the Habital® Service;
                            </p>
                            <p>
                                *Provide you with updates regarding Habital® Services;
                            </p>
                            <p>
                                *Perform internal market research, project planning, troubleshooting problems, and to
                                detect and protect against error, fraud or other criminal activity.
                            </p>
                            <p>
                                Habital® will not use your information for purposes other than those listed in this
                                Privacy Statement unless Measuremen obtains your consent first.
                            </p>
                            <h2>Use of Aggregated Anonymous User Data</h2>
                            <p>
                                Habital may disclose non-personally identifiable aggregated user data gathered from
                                Habital®
                                apps (without the inclusion of a user's name or other identifying information) to:
                                *conduct research into workplace strategy and management;
                                *for purposes of comparison of personal work profile to the broader community.
                            </p>
                            <p>
                                Measuremen also uses Google Analytics to allow Measuremen to see how you use
                                Measuremen’s
                                website and mobile application so that Measuremen can improve your experience.
                            </p>
                            <h2>Your Right to Delete Your Data</h2>
                            <p>
                                If you would like to cancel your Habital® account you may do so by sending an email to
                                the
                                Measuremen support team or delete your account via your profile page within the
                                application.
                                When you request cancellation of your account, your personally identifiable information
                                will
                                be deleted, including but not limited to your email address and name. Following
                                cancellation
                                or termination of your account, Habital® may continue to utilize de-identified and
                                anonymised historical data associated with your use of the Habital® apps.
                            </p>
                            <h2>Email Communications From Measuremen</h2>
                            <p>
                                Measuremen may provide their registered customers with periodical summaries of their
                                user
                                data. Measuremen also allows users to subscribe to email newsletters and from time to
                                time
                                may transmit emails promoting Habital®.
                                Habital® subscribers have the ability to opt-out of receiving Measuremen’s periodically
                                summaries and promotional emails and to terminate their newsletter subscriptions by
                                following the instructions provided in such emails.
                                Opting out in this manner will not end transmission of service-related emails, such as
                                email
                                alerts.
                            </p>
                            <h2>Data Security</h2>
                            <p>
                                Measurmemen uses a combination of firewall barriers, encryption techniques and
                                authentication procedures, among others, to maintain the security of your data and to
                                protect Habital® accounts and systems from unauthorized access.
                                When you register for Habital®, a password is required from you for your privacy and
                                security. This password is stored in an encrypted fashion in Measuremen’s systems.
                                It is important to understand that these precautions apply only to Measuremen’s website
                                and
                                apps. Measuremen has no control over how your information is stored, maintained or
                                displayed
                                by third parties or on third-party sites.
                            </p>
                            <h2>Your Responsibility for Maintaining the Confidentiality of your Login ID and
                                Password</h2>
                            <p>
                                You are responsible for maintaining the security of your login ID and password. If you
                                believe that your login ID or password have been compromised you should immediately
                                change
                                your password and contact support. Measuremen is not responsible if someone else
                                accesses
                                your account through registration information they have obtained from you or through a
                                violation by you of this Privacy Statement.
                            </p>
                            <h2>Updates to this Policy</h2>
                            <p>
                                Measuremen may update this policy periodically. The date last revised appears at the
                                bottom
                                of the Policy. Changes take effect immediately upon posting.
                            </p>
                            <p>Contact us at support@measuremen.io if you have any questions or concerns.
                            </p>
                            <p>If you have questions, comments, concerns, or feedback regarding this Policy, please send
                                an
                                email to Measuremen’s support team.
                            </p>
                            <p>This policy was last updated on March 1, 2019.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <footer-partial></footer-partial>
        </div>
    </div>
</template>

<script>

    export default {
        name: "TermsConditions",
        props: {
            title: String,
            content: String
        }
    };
</script>
