<template>
  <div
      id="app"
      v-bind:class="{
      loading: loading,
      ['page-' + routeName]: true,
      ['platform-' + platform]: true,
      'menu-no-space': !menuTakesSpaceTimed
    }"
  >
    <div v-if="loading || !appFullyLoaded" class="spinner">
      <lottie-animation
          path="/lottie/mm-top-loading.json"
          id="pagespinner"
          :autoplay="true"
          :loop="true"
      ></lottie-animation>
    </div>
    <div class="scroll-container view-h">
      <template v-if="appFullyLoaded">
        <div
            v-if="showNavigationTimed"
            class="scroll-container__menu"
            :class="{ 'xminimal-height': !menuTakesSpaceTimed }"
        >
          <navigation></navigation>
        </div>
        <div class="scroll-container__scroll">
          <transition name="fade" mode="out-in" @enter="enter">
            <router-view :key="$route.path"/>
          </transition>
        </div>
      </template>
    </div>
    <cookie-bar/>
  </div>
</template>

<script>
import Navigation from '@/components/shared/Navigation'
import {mapActions, mapGetters, mapState} from 'vuex'
import LottieAnimation from '@/components/shared/LottieAnimation'
import EventBus from '@/services/EventBus'
import CookieBar from '@/components/shared/CookieBar'
import BackgroundGradientService from '@/services/BackgroundGradientService.js'
import {Capacitor} from '@capacitor/core'
import {Keyboard} from '@capacitor/keyboard';
import {App as CapacitorApp, App} from '@capacitor/app';
import {Browser} from "@capacitor/browser";


export default {
  name: 'app',
  components: {
    Navigation,
    LottieAnimation,
    CookieBar
  },
  computed: {
    ...mapState(['loading', 'showNavigation', 'menuTakesSpace']),
    ...mapGetters(['authLoaded', 'appFullyLoaded']),
    platform() {
      if (Capacitor.getPlatform() === 'android') {
        return 'android';
      } else if (Capacitor.getPlatform() === 'ios') {
        return 'ios';
      } else {
        return 'web';
      }
    },
    routeName() {
      return this.routeNameValue
    },
    // needs to be timed at the right moment to prevent 'jumping' of the page while in a transition
    menuTakesSpaceTimed() {
      return this.menuTakesSpaceValue
    },
    showNavigationTimed() {
      return this.showNavigationValue
    }
  },
  data() {
    return {
      menuTakesSpaceValue: false,
      showNavigationValue: false,
      routeNameValue: '',
      firstLoad: true
    }
  },
  methods: {
    ...mapActions(['getUser']),
    enter(element) {
      const scrollElements = document.querySelectorAll(
          '.scroll-container__scroll'
      )
      scrollElements.forEach(function (item) {
        item.scrollTop = 0
      })

      this.onRouteChanged()
    },
    onRouteChanged() {
      this.menuTakesSpaceValue = this.menuTakesSpace
      this.routeNameValue = this.$route.name
      this.showNavigationValue = this.showNavigation
      BackgroundGradientService.updateShape()
      const scrollAreas = document.querySelectorAll('.scroll-container__scroll')
      scrollAreas.forEach(function (item) {
        // item.scrollTop = 0 /* fix ios scroll issue on first touch */
      })

      window.scrollTo(0, 0)
    }
  },

  beforeUpdate() {
    // set onload because no transition is triggered in that case
    if (this.firstLoad) {
      this.firstLoad = false
      this.onRouteChanged()
    }
  },
  mounted() {
    let app = this

    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.addListener('keyboardDidShow', (info) => {
        EventBus.$emit('keyboard-did-show')
      })
    }

    App.addListener('appStateChange', (state) => {
      EventBus.$emit('app-state-change', state)
    })

    CapacitorApp.addListener('appUrlOpen', async function ({url}) {
      console.log(`url: ${url}`)
      const urlObj = new URL(url)
      window.location = `${window.location.protocol}//${window.location.host}${urlObj.search}`
      await Browser.close()
    });

    EventBus.$on('on-server-error', function (error) {
      // disabled for now, see axios global interceptor
      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 500:
            app.$router.push({name: '500'}).catch(() => {
            })
            break
          case 401:
          case 419:
            location.reload()
            break
        }
      }
    })
  }
}

</script>
