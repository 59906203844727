import Vue from "vue";
import {Browser} from "@capacitor/browser";
import CapacitorCache from "./capacitorCache";
import {Auth0Client} from "@auth0/auth0-spa-js";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

let instance;

export const RECOVERABLE_ERRORS = [
    'login_required',
    'consent_required',
    'interaction_required',
    'account_selection_required',
    // Strictly speaking the user can't recover from `access_denied` - but they
    // can get more information about their access being denied by logging in
    // interactively.
    'access_denied'
];

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
                             onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
                             redirectUri = window.location.origin,
                             ...options
                         }) => {
    if (instance) return instance;

    // The 'instance' is simply a Vue object
    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                auth0Client: null,
                popupOpen: false,
                error: null
            };
        },
        methods: {
            /** Authenticates the user using a popup window */
            async loginWithPopup(options, config) {
                this.popupOpen = true;

                try {
                    await this.auth0Client.loginWithPopup(options, config);
                    this.user = await this.auth0Client.getUser();
                    this.isAuthenticated = await this.auth0Client.isAuthenticated();
                    this.error = null;
                } catch (e) {
                    this.error = e;
                    // eslint-disable-next-line
                    console.error(e);
                } finally {
                    this.popupOpen = false;
                }

                this.user = await this.auth0Client.getUser();
                this.isAuthenticated = true;
            },
            /** Handles the callback when logging in using a redirect */
            async handleRedirectCallback() {
                this.loading = true;
                try {
                    await this.auth0Client.handleRedirectCallback();
                    this.user = await this.auth0Client.getUser();
                    this.isAuthenticated = true;
                    this.error = null;
                } catch (e) {
                    this.error = e;
                } finally {
                    this.loading = false;
                }
            },
            /** Authenticates the user using the redirect method */
            loginWithRedirect(o) {
                return this.auth0Client.loginWithRedirect(o);
            },
            /** Returns all the claims present in the ID token */
            getIdTokenClaims(o) {
                return this.auth0Client.getIdTokenClaims(o);
            },
            /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
            getTokenSilently(o) {
                return this.auth0Client.getTokenSilently(o);
            },
            /** Gets the access token using a popup window */

            getTokenWithPopup(o) {
                return this.auth0Client.getTokenWithPopup(o);
            },
            /** Logs the user out and removes their session on the authorization server */
            logout(o) {
                return this.auth0Client.logout(o);
            },

            async logoutNative() {

                const postCacheClear = async () => {
                    this.auth0Client.cookieStorage.remove('auth0.is.authenticated');
                    const returnUrl = `${options.packageId}://${options.domain}`;
                    const url = this.auth0Client.buildLogoutUrl({
                        returnTo: returnUrl
                    });
                    console.log(`logoutUrl: ${url}`);
                    await Browser.open({
                        url: url,
                        toolbarColor: '#FF564D'
                    });
                };

                if (this.auth0Client.options.cache) {
                    return this.auth0Client.cacheManager.clear().then(() => postCacheClear());
                } else {
                    this.auth0Client.cacheManager.clearSync();
                    return postCacheClear();
                }
            },

            async loginNative(o = {}) {
                const {redirectMethod, ...urlOptions} = o;
                urlOptions.redirect_uri = `${options.packageId}://${options.domain}`;
                const url = await this.auth0Client.buildAuthorizeUrl(urlOptions);
                console.log(`url = ${url}`)
                await Browser.open({
                    url: url,
                    toolbarColor: '#FF564D'
                });
            },

            async createAuth0ClientCustom(o) {
                console.log('createAuth0ClientCustom');
                const auth0 = new Auth0Client(o);
                await this.checkSession(auth0);
                return auth0;
            },

            async checkSession(auth0) {
                try {
                    await auth0.getTokenSilently();
                } catch (error) {
                    if (!RECOVERABLE_ERRORS.includes(error.error)) {
                        throw error;
                    }
                }
            }
        },
        /** Use this lifecycle method to instantiate the SDK client */
        async created() {

            // Create a new instance of the SDK client using members of the given options object
            this.auth0Client = await this.createAuth0ClientCustom({
                ...options,
                client_id: options.clientId,
                redirect_uri: redirectUri,
                cache: new CapacitorCache()
            });


            try {
                // If the user is returning to the app after authentication..
                if (
                    window.location.search.includes("code=") &&
                    window.location.search.includes("state=")
                ) {
                    // handle the redirect and retrieve tokens
                    const {appState} = await this.auth0Client.handleRedirectCallback();

                    this.error = null;

                    // Notify subscribers that the redirect callback has happened, passing the appState
                    // (useful for retrieving any pre-authentication state)
                    onRedirectCallback(appState);
                }
            } catch (e) {
                this.error = e;
            } finally {
                // Initialize our internal authentication state
                console.log('finally')
                this.isAuthenticated = await this.auth0Client.isAuthenticated();
                console.log('this.isAuthenticated = ' + this.isAuthenticated);
                this.user = await this.auth0Client.getUser();
                console.log('done')
                this.loading = false;
            }
        },
    });

    return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useAuth0(options);
    }
};
