import Vue from "vue";

/* ---------------------------------------------------------------
* Background Gradient Transition
*
* @gradientId: gradient class name to be used ('gradient-' + gradientId)
* 
* --------------------------------------------------------------- */

export default new Vue({
  data() {
    return {
      timerGradient:null,
      backgroundShapeEnabled: false
    };
  },
  props: {
    msg: String
  },
  methods: {
    updateShape() {
      let app = document.getElementById("app");
      app.setAttribute('data-bg-shape', this.backgroundShapeEnabled);
    },
    setShape(enabled) {
      this.backgroundShapeEnabled = enabled;
    },
    setGradient(gradientId) {

      if(!gradientId) return;

      let app = this;

      // mark old gradients for deletion
      let el = document.getElementsByClassName('gradient');
      for (let i = 0; i < el.length; i++) {
        if (el[i].style.opacity == 1) {
          el[i].classList.add("remove");
        }
      }

      // create new gradient
      let newGradient = document.createElement("div");
      newGradient.className = "gradient gradient-" + gradientId;
      document.getElementById('gradient-bg').appendChild(newGradient);
      document.body.dataset.gradient = "gradient-" + gradientId;

      // show new gradient
      setTimeout(function () {
        newGradient.style.opacity = 1;
      }, 100);

      // remove timer
      if (this.timerGradient != null) {
        clearTimeout(this.timerGradient);
      }

      // remove gradients that are marked for deletion
      this.timerGradient = setTimeout(function () {
        let el = document.getElementsByClassName('remove');
        for (let i = 0; i < el.length; i++) {
          document.getElementById('gradient-bg').removeChild(el[i]);
        }
      }, 1500);
    }
  }
});