<template>
  <div class="visual-center">
    <div class="visual-center__content">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2 text-center">
            <h1>{{ $t('title_enter_access_code') }}</h1>

            <div v-html="text_enter_acccess_code"></div>

            <b-form novalidate>
              <b-form-group
                  class="mb-5"
                  :class="{ 'form-group--error': $v.form.accesscode.$error }"
              >
                <b-input
                    class="form-control input-numeric"
                    autocomplete="new-text"
                    type="text"
                    id="text"
                    v-model="form.accesscode"
                    :maxlength="6"
                    placeholder=""
                />

                <b-form-invalid-feedback id="errorCode"
                ><span>{{
                    $t('text_error_invalid_access_code')
                  }}</span></b-form-invalid-feedback
                >
              </b-form-group>

              <p v-if="error" class="error">
                <span>{{ errormsg }}</span>
              </p>
            </b-form>

            <div v-if="researches.length == 0" class="text-center mt-5">
              <button
                  @click="continueWithoutAccessCode"
                  class="btn btn-outline-white btn-sm mb-3"
              >
                {{ $t('button_continue_without_code') }}
              </button>
              <p class="small">{{ $t('text_continue_without_code') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapMutations, mapActions} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required, minLength, between, email} from 'vuelidate/lib/validators'
import RouteHelper from '@/services/RouteHelper'
import store from '@/store'
import EventBus from '@/services/EventBus'

export default {
  store,
  name: 'ResearchJoin',
  components: {},
  mixins: [validationMixin],
  computed: {
    ...mapState(['researches', 'research', 'survey', 'loading'])
  },
  data() {
    return {
      form: {
        accesscode: ''
      },
      error: false,
      errormsg: '',
      text_enter_acccess_code: this.$t('text_enter_acccess_code')
    }
  },
  watch: {
    'form.accesscode': function (v) {
      if (v.length == 6) {
        this.joinResearch(this.form.accesscode)
      }
    }
  },
  validations: {
    form: {
      accesscode: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    continueWithoutAccessCode() {
      this.joinResearch('111111')
    },
    joinResearch(code) {
      this.error = false

      // we need the research before we can continue
      // getNextRoute need the information from the research and user object
      store
          .dispatch('joinResearch', code)
          .then((response) => {
            store.commit('activeResearchId', response.data.id)
            store.commit('research', response.data)
            store.commit('loading', false)
            // continue to next route
            this.$router
                .replace(RouteHelper.getNextRoute())
                .catch(() => {
                })
          })
          .catch((error) => {
            // API fail
            store.commit('loading', false)
            this.error = true
            this.errormsg = 'Unknown error'
            if (error && error.response) {
              if (error.response.status === 404) {
                this.errormsg = this.$t('text_unknown_research_access_code');
              } else if (error.response.data.data) {
                if (error.response.data.data.error_message) {
                  this.errormsg = error.response.data.data.error_message
                } else if (error.response.data.data.message) {
                  this.errormsg = error.response.data.data.message;
                }
              }
            }
            console.log('this.errormsg ', this.errormsg);
          })
    }
  },
  mounted() {
  }
}
</script>
