<template>
  <div>
    <div class="section">

      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2 content-min-height">

            <h1> {{ $t(title) }}</h1>

            <div v-html="contentHTML">

            </div>
            
          </div>
        </div>
      </div>

      <footer-partial></footer-partial>

    </div>
  </div>
</template>

<script>

import store from '@/store';

export default {
  store,
  name: "LandingPage",
  data() {
    return {
      title: String,
      content: String,
      error: String
    }
  }, 
  beforeRouteEnter (to, from, next) {
    store.dispatch("getLandingPage", to.params.slug).then(response => {
      store.commit('loading', false);
      next(vm => {
        vm.title = `${response.data.title}`;
        vm.content = `${response.data.text}`;          
      });  
    }).catch(error => {
      store.commit('loading', false);      
      if (error && error.response) {
        next('/404');
      }
    });
  },
  computed: {
    contentHTML:function() {
      return this.$t(`${this.content}`);
    }
  },
  mounted() {

  }
};
</script>
