<template>
  <div>
    <template v-if="true"> <!-- numQuestions > 5 || numQuestions == 1 -->
      <b-progress :value="progress" :max=100></b-progress>
    </template>
    <template v-else>
      <div class="progress-stepped">
          <div class="container">
            <div v-for="index in numQuestions" :key="index" :class="{'OK': stepCompleted(index) }"></div>
          </div>
      </div>      
    </template>
  </div>
</template>

<script>
export default {
  name: "UProgressBar", 
  props: {
    numQuestions: Number,
    progress: Number
  },
  methods: {
    stepCompleted(index) {
      return (index - 1) <= Math.ceil(this.numQuestions / 100 * this.progress);
    }
  },
  data() {
    return {
    };
  },
};
</script>