<template>
  <div>
      <div class="section">
        <div class="container">

          <div class="row">
            <div class="col-md-6 offset-md-3 pb-5">
              
              <h1>{{ $t(title) }}</h1>
              <div v-html="contentHTML">

              </div>
              
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 offset-md-2 pb-5">

<div class="table-container">
<table border="0" class="table small">
<thead>
<tr>
<th>Name</th>
<th>Domain</th>
<th>Purpose</th>
<th>Expiration</th>
<th>Type</th>
</tr>
</thead>
<tbody>
<tr>
<td>rememberMe</td>
<td>habital.io</td>
<td>Functional cookie</td>
<td>10 days</td>
<td>HTTP Cookie</td>
</tr>
<tr>
<td>_ga</td>
<td>habital.io</td>
<td>Google Analytics </td>
<td>2 year</td>
<td>HTTP Cookie</td>
</tr>
<tr>
<td>_gid</td>
<td>habital.io</td>
<td>Google Analytics</td>
<td>Session</td>
<td>HTTP Cookie</td>
</tr>
<tr>
<td>_gat_UA-130690350-1</td>
<td>habital.io</td>
<td>Google Analytics</td>
<td>Session</td>
<td>HTTP Cookie</td>
</tr>
</tbody>
</table>
</div>

            </div>
          </div>
        </div>

        <footer-partial></footer-partial>

      </div>
  </div>
</template>

<script>

export default {
  name: "CookieStatement",
  props: {
    title: String,
    content: String
  }, 
  computed: {
    contentHTML:function() {
      return this.$t(`${this.content}`);
    }
  }
};
</script>
