<template>
  <div>
      <div class="section">

        <div class="container">
          <div class="row">
            <div class="col-md-6 offset-md-3 content-min-height">
              
              <h1>{{ $t(title) }}</h1>
              <div v-html="contentHTML">

              </div>
              
            </div>
          </div>
        </div>

        <footer-partial></footer-partial>

      </div>
  </div>
</template>

<script>

export default {
  name: "DefaultContent",
  props: {
    title: String,
    content: String
  }, 
  computed: {
    contentHTML:function() {
      return this.$t(`${this.content}`);
    }
  }
};
</script>
