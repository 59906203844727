<template>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <b-form-group :class="{ 'form-group--error': !isValid }">
        <b-form-checkbox-group
          class="custom-controls-stacked"
          stacked
          v-model="internalValue"
          name="radio"
        >
          <b-form-checkbox
            v-for="item in options"
            :key="item.row"
            :value="item.option"
            v-if="item.enabled"
            >
            <span v-html="item.text"></span>
          </b-form-checkbox
          >
          <div v-if="textoption && textoption.enabled">
            <b-form-checkbox
              ref="checkboxExtraValue"
              class="form-control no-border"
              :value="textoption.option"
              >
              <span v-html="textoption.text"></span>
            </b-form-checkbox
            >
            <b-input
              v-show="showTextValue"
              class="form-control input-singleline"
              type="text"
              v-model="internalValueExtra"
              :maxlength="1024"
              id="collapse_input"
            />
          </div>
        </b-form-checkbox-group>
      </b-form-group>

      <p v-if="errormsg" class="invalid-feedback show text-center mb-1">
        {{ errormsg }}
      </p>
    </div>
  </div>
</template>

<script>
import EventBus from '@/services/EventBus'

export default {
  name: 'QuestionCheckbox',
  props: {
    options: Array,
    textoption: Object,
    valid: Boolean,
    value: [Number, String, Array],
    value_extra: String,
    errormsg: String
  },
  computed: {
    showTextValue() {
      if (this.textoption) {
        for (var i in this.internalValue) {
          if (this.textoption.option == this.internalValue[i]) {
            return true
          }
        }
      }
      return false
    }
  },
  methods: {
    getValue() {
      return this.internalValue.join('|')
    },
    getValueExtra() {
      return this.internalValueExtra
    },
    stringToArray(val) {
      if (!val) return
      // split the value string
      let newValue = val.split('|')
      if (newValue.length > 0) {
        this.internalValue = newValue
      }
    }
  },
  watch: {
    valid(v) {
      this.isValid = v
    },
    value(v) {
      this.internalValueExtra = this.stringToArray(v)
    },
    value_extra(v) {
      this.internalValueExtra = v
    }
  },
  data() {
    return {
      isValid: this.valid,
      internalValue: [],
      internalValueExtra: ''
    }
  },
  mounted() {
    this.stringToArray(this.value)
    this.internalValueExtra = this.value_extra

    // fix textfield position when keyboard in expanded
    EventBus.$on('keyboard-did-show', function () {
      document.getElementById('question-scroll-container').scrollTop = 99999
    })
  }
}
</script>
