import {getInstance} from "./index";

export const authTokenInterceptor = ({
                                         header = 'Authorization',
                                         headerPrefix = 'Bearer ',
                                     }) => async (requestConfig) => {
    const accessToken = await getInstance().getTokenSilently();
    if (accessToken) {
        requestConfig.headers[header] = `${headerPrefix}${accessToken}`;
    }
    return requestConfig;
}

export const applyAuthTokenInterceptor = (axios, config) => {
    if (!axios.interceptors)
        throw new Error(`invalid axios instance: ${axios}`);
    axios.interceptors.request.use(authTokenInterceptor(config));
};
