import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import EventBus from '@/services/EventBus';
import Sampling from '@/models/sampling';
import {Storage} from "@capacitor/storage";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        samplingNotification: null,
        samplingsToDo: [],
        research: {},
        researches: [],
        loading: false,
        authLoaded: false,
        appFullyLoaded: false,
        user: {},
        activeResearchId: -1,
        showNavigation: true,
        menuTakesSpace: true,
        needToSeeFlaskAnimation: false,
        needToSeeSamplingCompleted: false,
        locale: '',
        fcmToken: null,
    },
    getters: {

        // research
        samplingNotification(state) {
            return state.samplingNotification;
        },
        samplingsTodoCount(state, getters) {
            return state.samplingsToDo.length;
        },
        samplingsTodo(state) {
            return state.samplingsToDo;
        },
        samplingTodo: (state) => (params) => {
            return state.samplingsToDo.find(sampling => sampling.matchesParams(params));
        },
        activeSampling: state => {
            return state.samplingsToDo.find(sampling => sampling.isOpen());
        },
        needToSeeIntroduction(state) {
            return state.research.intro_texts &&
                state.research.intro_texts.length && // has intro
                !state.research.has_seen_intro; // has not seen
        },

        hasResearchIntroduction(state) {
            return state.research.intro_texts && state.research.intro_texts.length > 0;
        },

        activeResearchId(state) {
            return state.activeResearchId;
        },
        needToSeeFlaskAnimation(state) {
            return state.needToSeeFlaskAnimation;
        },

        needToSeeSamplingCompleted(state) {
            return state.needToSeeSamplingCompleted;
        },

        // state
        authLoaded(state) {
            return state.authLoaded;
        },

        appFullyLoaded(state) {
            return state.appFullyLoaded;
        },

        research(state) {
            return state.research;
        },

        sampling(state) {
            return state.sampling;
        },

        user(state) {
            return state.user;
        },

        fcmToken(state) {
            return state.fcmToken;
        },

        getLocale(state) {
            return state.locale
        }
    },
    mutations: {
        showNavigation(state, navigation) {
            state.showNavigation = navigation;
        },
        menuTakesSpace(state, space) {
            state.menuTakesSpace = space;
        },
        researches(state, researches) {
            state.researches = researches;
        },
        research(state, research) {
            state.research = research;
        },
        loading(state, loading) {
            state.loading = loading;
        },
        user(state, user) {
            state.user = user;
        },
        activeResearchId(state, activeResearchId) {
            state.activeResearchId = activeResearchId;
        },
        authLoaded(state, authLoaded) {
            state.authLoaded = authLoaded;
        },
        setAppFullyLoaded(state, appFullyLoaded) {
            state.appFullyLoaded = appFullyLoaded;
        },
        setFcmToken(state, fcmToken) {
            state.fcmToken = fcmToken;
        },
        setNeedToSeeFlaskAnimation(state, needToSeeFlaskAnimation) {
            state.needToSeeFlaskAnimation = needToSeeFlaskAnimation;
        },
        setSamplingFinished(state, samplingData){
            let samplings = state.samplingsToDo;
            const idx = samplings.findIndex(sampling => sampling.matchesParams(samplingData));
            if(idx >= 0) {
                samplings[idx].dataSetStatus = 'complete'
            }
            state.samplingsToDo = samplings;
        },
        setSamplingsToDo(state, samplingsToDo) {
            let todo = [];
            if (samplingsToDo) {
                for (let i in samplingsToDo) {
                    const questionList = samplingsToDo[i];
                    const samplings = questionList.samplings;
                    for (let j in samplings) {
                        const sampling = samplings[j];

                        if (sampling.sampling_times === null || sampling.sampling_times.length === 0) {
                            todo.push(new Sampling(questionList, sampling));
                            continue;
                        }
                        for (let k in sampling.sampling_times) {
                            todo.push(new Sampling(questionList, sampling, sampling.sampling_times[k]));
                        }
                    }
                }
            }
            state.samplingsToDo = todo;
        },
        setSamplingNotification(state, notificationData) {
            state.samplingNotification = notificationData;
        },

        setLocale(state, locale) {
            state.locale = locale;
        }
    },

    actions: {
        async setLocale(context, locale) {
            await Storage.set({key: "locale", value: locale})
            context.commit('setLocale', locale);
            return true
        },

        // support question
        sendSupportQuestion(context, postData) {
            context.commit('loading', true);
            return axios.post(`/support-question`, postData);
        },

        // sign up
        signUp(context, postData) {
            context.commit('loading', true);
            return axios.post(`/auth/register`, postData);
        },

        // password
        requestPasswordReset(context, postData) {
            context.commit('loading', true);
            return axios.post(`/auth/password/email`, postData);
        },

        confirmPasswordReset(context, postData) {
            context.commit('loading', true);
            return axios.post(`/auth/password/reset`, postData);
        },

        setPassword(context, postData) {
            context.commit('loading', true);
            return axios.post(`/auth/verify`, postData);
        },

        getUser(context) {
            return axios.get('auth/user');
        },

        deleteUser(context) {
            context.commit('loading', true);
            return axios.get(`/auth/user/delete`);
        },

        updateUser(context, payload) {
            const postData = {
                ...payload,
                fcm_token: context.state.fcmToken,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZoneName
            };
            console.log("updateUser: " + JSON.stringify(postData));
            return axios.put(`/auth/user`, postData);
        },

        // getResearches
        // Gets all Research the user is joined in
        getResearches(context, postData) {
            context.commit('loading', true,);
            return axios.get('/research');
        },
        getResearch(context) {
            context.commit('research', {});
            context.commit('loading', true);
            return axios.get(`/research/${this.state.activeResearchId}`);
        },
        getResearchDashboard(context) {
            context.commit('loading', true);
            return axios.get(`/research/${this.state.activeResearchId}/dashboard`)
        },
        getSamplingsTodoToday(context) {
            context.commit('setSamplingsToDo', {});
            context.commit('loading', true);
            return axios.get(`/research/${this.state.activeResearchId}/sampling/todo/today`);
        },


        joinResearch(context, code) {
            context.commit('loading', true);
            return axios.post(`/research/join`, {researchCode: code});
        },

        samplingsTodoToday(context) {
            context.commit('loading', true);
            return axios.get(`/research/${this.state.activeResearchId}/sampling/todo/today`)
        },
        samplingStart(context, parameters) {
            context.commit('loading', true);
            return axios.get(`/research/${this.state.activeResearchId}/sampling/${parameters.samplingId}/start`)
        },
        samplingTimeStart(context, parameters) {
            context.commit('loading', true);
            return axios.get(`/research/${this.state.activeResearchId}/sampling/${parameters.samplingId}/samplingTime/${parameters.samplingTimeId}/start`)
        },


        samplingNext(context, data) {
            context.commit('loading', true);
            return axios.get(`/research/${this.state.activeResearchId}/questionList/${data.question_list_id}/dataSet/${data.data_set_id}/next`)
        },
        samplingPrev(context, data) {
            context.commit('loading', true);
            return axios.get(`/research/${this.state.activeResearchId}/questionList/${data.question_list_id}/dataSet/${data.data_set_id}/previous`)
        },
        samplingPost(context, postData) {
            context.commit('loading', true);
            return axios.post(`/research/${this.state.activeResearchId}/questionList/${postData.question_list_id}/update`, postData)
        },
        samplingFinish(context, postData) {
            context.commit('loading', true);
            return axios.post(`/research/${this.state.activeResearchId}/questionList/${postData.question_list_id}/finish`, postData)
        },

        // Not Working ------------------------------------------------------------------------------
        notWorking(context, postData) {
            context.commit('loading', true);
            return axios.get(`/auth/user/not-working/${this.state.activeResearchId}`)
        },

        // Result ------------------------------------------------------------------------------
        getResults(context, researchId) {
            context.commit('loading', true);
            return axios.get(`/research/${this.state.activeResearchId}/results/sampling?complete=2`)
        },

        // Landing page ------------------------------------------------------------------------
        getLandingPage(context, slug) {
            context.commit('loading', true);
            return axios.get(`/research/landing/${slug}`)
        }
    }

})
