<template>
  <div>
      <div class="section section-bg-shape">
        <div class="container">

          <template v-if="completed">

            <div class="row mb-5">
              <div class="col-md-8 offset-md-2 text-center">

                <h1>{{ $t("title_support") }}</h1>
                <p>{{ $t("text_support_thank_you") }}</p>
                <router-link :to="{'name': 'home'}" class="btn btn-outline-white">{{ $t("button_to_home") }}</router-link>

              </div>
            </div>

          </template>
          <template v-else>

            <div class="row mb-5">
              <div class="col-md-8 offset-md-2 text-center">

                <h1>{{ $t("title_support") }}</h1>
                <p>{{ $t("text_support") }}</p>

              </div>
            </div>
            <div class="row">
              <div class="col-md-6 offset-md-3">

                <b-form @submit.prevent="submit" novalidate>
                  <b-form-group :class="{ 'form-group--error': $v.form.name.$dirty &$v.form.name.$error }">
                    <label for="name">{{ $t("form_name_label") }}</label>
                    <b-input  class="form-control" autocomplete="new-name" type="text" id="name"
                      v-model="form.name"
                      @change="$v.form.name.$touch()"
                      :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                      aria-describedby="errorName"
                    />
                    <b-form-invalid-feedback id="errorName">{{ $t("form_name_invalid") }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group :class="{ 'form-group--error': $v.form.email.$dirty &$v.form.email.$error }">
                    <label for="email">{{ $t("form_email_label") }}</label>
                    <b-input  class="form-control" autocomplete="new-email" type="email" id="email"
                      v-model="form.email"
                      @change="$v.form.email.$touch()"
                      :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
                      aria-describedby="errorEmail"
                    />
                    <b-form-invalid-feedback id="errorEmail">{{ $t("form_email_invalid") }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group :class="{ 'form-group--error': $v.form.message.$dirty &$v.form.message.$error }">
                    <label for="message">{{ $t("form_message_label") }}</label>
                    <b-textarea  class="form-control" autocomplete="new-message" type="text" id="message"
                      v-model="form.message"
                      @change="$v.form.message.$touch()"
                      :state="$v.form.message.$dirty ? !$v.form.message.$error : null"
                      aria-describedby="errorMessage"
                    />
                    <b-form-invalid-feedback id="errorMessage">{{ $t("form_message_invalid") }}</b-form-invalid-feedback>
                  </b-form-group>

                  <!-- <router-link :to="{'name': 'home'}" class="btn-back float-left">{{ $t("menu_back") }}</router-link> -->
                  <p v-if="form.error" class="error">{{form.error}}</p>
                  <div class="text-center pt-1">
                    <b-button v-if="!loading" :disabled="$v.form.$invalid" type="submit" class="btn btn-outline-white">{{ $t("button_submit") }}</b-button>
                    <div v-if="loading" class="btn-spinner-wrapper">
                      <lottie-animation
                        path="/lottie/mm-spinner-star.json"
                        id="buttonspinner"
                        :autoplay="true"
                        :loop="true"
                      ></lottie-animation>
                    </div>
                  </div>
                </b-form>

              </div>
            </div>

          </template>

        </div>
      </div>

      <footer-partial></footer-partial>

      <div class="bg-shape"></div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, between, email } from "vuelidate/lib/validators";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import LottieAnimation from "@/components/shared/LottieAnimation";
import {urlVars} from '../services/UrlParams'

export default {
  name: "support",
  components: {LottieAnimation},
  mixins: [validationMixin],
  data() {
    return {
      completed: false,
      loading:false,
      form: {
        name: "",
        email: "",
        message: "",
        error: ""
      }
    };
  },
  computed: {
    ...mapState(["researches", "research", "survey"])
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        email
      },
      message: {
        required,
        minLength: minLength(2)
      }
    }
  },
  methods: {
    ...mapGetters(['user']),
    submit() {

      this.loading = true; // custom button throbber

      var postData = {
        name: this.form.name,
        email: this.form.email,
        body: this.form.message
      }

      this.$store.dispatch("sendSupportQuestion", postData)
        .then(response => {
          this.completed = true;
        })
        .catch(error => {
          if (error && error.response) {
            this.form.error = error.response.data.error_message || 'unknown error';
          }
        })
        .then(e => {
          this.$store.commit('loading', false);
          this.loading = false;
        });
    }
  },
  mounted() {
    if(urlVars.urlEmail) {
      this.form.email = urlVars.urlEmail
    } else {
      // auto fill email
      const user = this.user();
      if (user && user.email) {
        this.form.email = user.email;
      }
    }
  }
};
</script>
