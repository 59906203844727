<template>
  <div class="text-center">
    <div class="form-group" :class="{ 'form-group--error': !isValid }">
      <input
        class="form-control input-numeric"
        type="text"
        v-model="internalValue"
        @blur="onBlur()"
      />
    </div>
    <p v-if="errormsg" class="invalid-feedback show text-center mb-1">
      {{ errormsg }}
    </p>
  </div>
</template>     
          
<script>

import EventBus from '@/services/EventBus'

export default {
  name: 'QuestionNumber',
  props: {
    valid: Boolean,
    value: [String, Number],
    precision: Number,
    errormsg: String
  },
  methods: {
    onBlur: function () {
      let num = parseFloat(this.internalValue.toString().split(',').join('.'))
      if (isNaN(num)) {
        this.internalValue = ''
        return
      }
      num = parseFloat(num.toFixed(parseInt(this.precision)))
      this.internalValue = this.getLocalizedValue(num)
    },

    getLocalizedValue(v) {
      if (v != undefined) {
        let num = v.toString()
        num = num.replace('.', this.$t('form_decimal_separator'))
        return num
      }
      return ''
    },

    getNativeValue(v) {
      if (v != undefined) {
        let num = v.toString()
        num = num.replace(this.$t('form_decimal_separator'), '.')
        return parseFloat(num)
      }
      return ''
    },

    getValue() {
      return this.getNativeValue(this.internalValue)
    }
  },
  watch: {
    valid(v) {
      this.isValid = v
    },
    value(v) {
      this.internalValue = this.getLocalizedValue(this.value)
    }
  },
  data() {
    return {
      isValid: this.valid,
      internalValue: this.value
    }
  },
  mounted() {
    this.internalValue = this.getLocalizedValue(this.value)

    // fix textfield position when keyboard in expanded
    EventBus.$on('keyboard-did-show', function () {
      document.getElementById('question-scroll-container').scrollTop = 0
    })
  }
}
</script>