<template>
  <div v-if="isWeb" class="cookie-bar text-center" :class="{'show' : show}">
    <p v-html="$t('text_cookie_bar')"></p>
    <button @click="close()" class="btn btn-outline-white btn-sm">{{$t('button_cookie_bar')}}</button>
  </div>
</template>

<script>
import {Capacitor} from "@capacitor/core";

export default {
  name: "CookieBar", /* footer does not work */
  methods: {
    close() {
      window.localStorage.setItem('allowCookies', true);
      this.show = false;
    }
  },
  computed: {
    isWeb() {
      return Capacitor.platform === 'web';
    }
  },
  data() {
    return {
      show: false
    };
  },
  created() {
    if (!window.localStorage.getItem('allowCookies')) {
      this.show = true;
    }
  }
};
</script>
