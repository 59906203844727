<template>
  <div>

    <b-carousel :indicators="items.length > 1" :interval="0" v-model="slide" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
      <b-carousel-slide v-for="(item, index) in items" :key="item.title">

        <div class="center-container-ie11fix">
          <div class="center-container">
            <div class="center-content">

              <h2 v-html="item.title"></h2>
              <hr/>

              <p class="large" v-html="item.body"></p>

              <div v-if="item.extra_body">
                <p><b-link v-b-toggle="'collapse'+index" @click="onExpand">{{ $t("button_read_more") }}</b-link></p>
                <b-collapse :id="'collapse'+index">
                  <p class="large" v-html="item.extra_body"></p>
                </b-collapse>
              </div>

            </div>
          </div>
        </div>

      </b-carousel-slide>
    </b-carousel>

  </div>
</template>

<script>

import EventBus from "@/services/EventBus";

export default {
  name: "Carousel",
  components: {},
  props: {
    items: Array,
    value: Number
  },
	computed: {
		// `v-model` works by binding a `value` and then
		// listening for "input" events. When layering
		// multiple `v-model` components, you need to emit
		// those "input" events back up the chain.
		slide: {
			get() { return this.value },
			set(v) { this.$emit("input", v) }
		}
	},
  methods: {
    updateModel() {
      this.$emit('input', this.slide);
    },
    // height animation fix for b-carousel
    onSlideStart() {
        let carousel = document.querySelector('.carousel');
        if(carousel) {
          carousel.style.height = carousel.clientHeight + 'px';
        }
    },
    onExpand() {
        let carousel = document.querySelector('.carousel');
        if(carousel) {
          carousel.style.height = 'auto';
        }
    },
    onSlideEnd() {
        let carousel = document.querySelector('.carousel');
        if(carousel) {
          // save old height and set height to 'auto' so we can measure the new height
          let oldHeight = carousel.clientHeight;
          carousel.style.height = 'auto';
          let newHeight = carousel.clientHeight;

          // set height back to old height and set new height one 'tick' later so the transition is triggered
          carousel.style.height = oldHeight + 'px';
          setTimeout(function() {
            carousel.style.height = newHeight + 'px';
          });
        }
    }
  },
  mounted() {

    var app = this;
    if(this.items.length == 1) {
      this.indicators = false;
    }

    EventBus.$on('swipe-left', function() {
   //     app.slide++;
    });

    EventBus.$on('swipe-right', function() {
     //   app.slide--;
    });
  }
};
</script>
