<template>
    <div class="visual-center" v-if="show">
      <div class="visual-center__content">
        <div class="container">
          <div class="row">
            <div class="col col-md-6 offset-md-3 text-center">
              <h1>{{ $t('title_select_research') }}</h1>

              <button
                v-for="item in researches"
                :key="item.id"
                :class="{ inactive: !item.active }"
                class="btn btn-outline-white-select"
                @click="selectResearch(item.id)"
              >
                {{ item.title }}
              </button>
              <div class="text-center mt-5">
                <router-link
                  :to="{ name: 'research-join' }"
                  class="btn btn-outline-white btn-sm"
                  >{{ $t('button_enter_new_access_code') }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import EventBus from '@/services/EventBus'
import RouteHelper from '@/services/RouteHelper'
import store from '@/store'

export default {
  store,
  name: 'ResearchSelect',
  components: {},
  data() {
    return {
      show: true,
      post: null,
      error: null
    }
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch('getResearches')
      .then((response) => {
        store.commit('researches', response.data)
        store.commit('loading', false)
        next()
      })
      .catch((error) => {
        if (error && error.response) {
          this.error = error
        }
        store.commit('loading', false)
      })
  },

  computed: {
    ...mapState(['researches', 'research', 'loading']),
    ...mapGetters(['activeResearchId'])
  },
  methods: {
    selectResearch(researchId) {
      store.commit('activeResearchId', researchId)

      // we need the research before we can continue
      // getNextRoute need the information from the research and user object
      if (researchId) {
        store
          .dispatch('getResearch')
          .then((response) => {
            store.commit('research', response.data)
            store.commit('loading', false)
            // continue to next route
            this.$router
              .replace(RouteHelper.getNextRoute())
              .catch(() => {})
          })
          .catch((error) => {
            store.commit('loading', false)
            if (error && error.response) {
              store.commit('loading', false)
            }
            EventBus.$emit('on-server-error', error)
          })
      }
    }
  }
}
</script>
