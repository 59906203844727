<template>
    <div class="ul-modal">
      <div class="ul-modal--container">
        <button class="ul-modal--b-close" @click="close()" button-close-modal>×</button>
        <div class="ul-modal--content">
          <slot></slot>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "ULModal",
  data() {
    return {
      
    };
  },
  methods: {
    close() {
      this.$el.classList.remove('ul-modal-open');
    },
    open() {
      this.$el.classList.add('ul-modal-open');
    }    
  },
  mounted() {
    let el = this.$el.querySelectorAll('[button-close-modal]');
    for(let i=0; i< el.length; i++) {
      el[i].removeEventListener("click", this.close);
      el[i].addEventListener("click", this.close);
    }
  }
};
</script>