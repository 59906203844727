<template>
  <div class="lottie-background">

    <div class="section pt-0">

      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-8 offset-md-2 text-center">
            <lottie-animation path="/lottie/mm-shapeshifter.json" :autoplay="true" :loop="true" id="mm-shapeshifter"
                              class="mm-shapeshifter"></lottie-animation>

            <div class="slider-container">
              <carousel v-model="slide" :items="carouselItems"></carousel>
            </div>

            <div class="mb-3">
              <button class="btn btn-outline-white" :class="{'disabled' : loggingIn}" @click="login">{{
                  $t("button_sign_up_or_in")
                }}
              </button>
            </div>

            <div class="mb-3">
              <a href="https://discover.habital.io/" target="_blank">{{ $t("footer_about") }}</a>
            </div>

          </div>
        </div>
      </div>
    </div>

    <footer-partial></footer-partial>

  </div>
</template>

<script>
import Carousel from "@/components/shared/Carousel";
import LottieAnimation from "@/components/shared/LottieAnimation";
import {Capacitor} from "@capacitor/core";

export default {
  name: "Home",
  components: {
    Carousel, LottieAnimation
  },
  data() {
    return {
      loggingIn: false,
      anim: null,
      slide: 0,
      carouselItems: []
    };
  },
  mounted() {
    let count = 1;
    while (this.$te(`title_home${count}`)) {
      this.carouselItems.push({
        title: this.$t(`title_home${count}`),
        body: this.$t(`text_home${count}`)
      });
      count++;
    }
  },
  methods: {
    async login() {
      this.loggingIn = true;
      if (Capacitor.getPlatform() === 'web') {
        this.$auth.loginWithRedirect();
      } else {
        await this.$auth.loginNative();
      }
    },
  }
};


function base64URLEncode(str) {
  return str
      .toString("base64")
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
}


</script>
