import Vue from "vue";
import {mapGetters, mapMutations, mapState} from "vuex";
import store from '@/store';

export default new Vue({
    store,
    computed: {
        ...mapState(['researches', 'research', 'loading']),
        ...mapGetters([
            'needToSeeIntroduction',
            'needToSeeFlaskAnimation',
            'needToSeeSamplingCompleted',
            'activeResearchId',
            'samplingNotification',
            'samplingTodo',
            'activeSampling',
            'research',
            'user'
        ])
    },
    methods: {
        ...mapMutations(['setSamplingNotification']),
        getNextSamplingRoute(samplingData) {
            if (samplingData.needsToSeeIntro) {
                return {
                    name: 'sampling-introduction', params: {
                        samplingData: {...samplingData, needsToSeeIntro: false},
                        items: samplingData.introTexts,
                    }
                }
            }
            if (samplingData.dataSetStatus !== 'complete') {
                const routeName = samplingData.samplingTimeId === null
                    ? 'sampling'
                    : 'sampling-time';
                return {name: routeName, params: {...samplingData, samplingData: samplingData}}
            }
            if (samplingData.needsToSeeOutro) {
                return {
                    name: 'sampling-outro', params: {
                        samplingData: {...samplingData, needsToSeeOutro: false},
                        items: samplingData.outroTexts,
                    }
                }
            }
            return this.getNextRoute();
        },
        getNextRoute() {

            if (this.user.id) {

                if (!this.activeResearchId) {
                    return {name: 'research-join'};
                }

                // ----------------------------------------------------------------------------------------------
                // User has not seen introduction
                // ----------------------------------------------------------------------------------------------
                if (this.needToSeeIntroduction) {
                    return {name: 'research-introduction'};


                // ----------------------------------------------------------------------------------------------
                // User is busy filling in a sampling
                // ----------------------------------------------------------------------------------------------
                } else if (this.activeSampling) {
                    return this.getNextSamplingRoute(this.activeSampling);


                // ----------------------------------------------------------------------------------------------
                // User clicked on a notification while the app was closed
                // ----------------------------------------------------------------------------------------------
                } else if (this.samplingNotification) {
                    const sampling = this.samplingTodo(this.samplingNotification)
                    this.setSamplingNotification(null);
                    if (sampling) {
                        return this.getNextSamplingRoute(sampling);
                    }


                // ----------------------------------------------------------------------------------------------
                // User needs to see sampling completed screen first
                // ----------------------------------------------------------------------------------------------
                } else if (this.needToSeeSamplingCompleted) {
                    return {name: 'sampling-completed'};
                }

                return {name: 'dashboard'};
            }
            return {name: 'home'};
        }
    }
});
