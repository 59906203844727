<template>
  <div class="visual-center">
    <div class="visual-center__content">

      <div class="container">

        <div class="row">
          <div class="col-md-6 offset-md-3 text-center">

            <h1>{{ $t('title_account') }}</h1>
            <div class="mb-4">
              {{ user.email }}
            </div>
            <button
                @click="toggleSelectLanguage()"
                class="btn"
                :class="{'btn-outline-white-down': !showLanguageSelect, 'btn-outline-white-up': showLanguageSelect}"
            >{{ $t('button_language') }}
            </button
            >
            <ul v-if="showLanguageSelect" class="select-language">
              <li
                  v-for="(value, key) in languages"
                  v-bind:key="key"
                  v-bind:class="{ active: key == currentLocale }"
                  @click="setLanguage(key)"
              >{{ value }}
              </li>
            </ul>
            <button
                @click="switchResearch()"
                class="btn btn-outline-white-select"
            >{{ $t('button_switch_research') }}
            </button
            >
            <button
                @click="$refs.modalAccount.open()"
                class="btn btn-outline-white-select"
            >
              {{ $t('button_delete_account') }}
            </button>
            <button @click="signout" class="btn btn-outline-white-select">
              {{ $t('button_sign_out') }}
            </button>

            <div v-if="appInfo.version" class="mt-3">
              {{ $t('text_app_version') + ': ' + appInfo.version }}
            </div>

            <ULModal ref="modalAccount">
              <h4>{{ $t('title_confirm_delete_account') }}</h4>
              <p class="pb-4">{{ $t('text_confirm_delete_account') }}</p>
              <button
                  button-close-modal=""
                  class="btn btn-outline-white btn-sm ml-4 mr-4 mb-5"
              >
                {{ $t('button_cancel') }}
              </button>
              <button
                  button-close-modal=""
                  @click="confirmDeleteAccount()"
                  class="btn btn-outline-white btn-sm btn-sm ml-4 mr-4 mb-5"
              >
                {{ $t('button_confirm_delete_account') }}
              </button>
            </ULModal>

          </div>
        </div>
      </div>

    </div>
  </div>


</template>

<script>
import ULModal from '@/components/shared/ULModal'
import {mapActions, mapGetters} from 'vuex';
import {App} from '@capacitor/app';
import {Capacitor} from "@capacitor/core";


export default {
  name: 'Account',
  components: {ULModal},
  data() {
    return {
      appInfo: {},
      showLanguageSelect: false,
      languages: {
        nl: this.$t('language_dutch'),
        en: this.$t('language_english'),
        fr: this.$t('language_french')
      },
      currentLocale: this.$i18n.locale
    }
  },
  mounted() {
    if (Capacitor.getPlatform() !== 'web') {
      App.getInfo().then(appInfo => this.appInfo = appInfo)
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['setLocale']),
    toggleSelectLanguage() {
      this.showLanguageSelect = !this.showLanguageSelect;
    },
    setLanguage(lang) {
      this.setLocale(lang).then(() => {
        window.location.reload()
      });
    },
    switchResearch() {
      this.$router.push({name: 'research'})
    },
    signout() {
      if (Capacitor.getPlatform() === 'web') {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const returnUrl = protocol + "//" + hostname;
        this.$auth.logout({
          returnTo: returnUrl
        });
      } else {
        this.$auth.logoutNative()
      }
    },
    confirmDeleteAccount() {
      let app = this
      return this.$store
          .dispatch('deleteUser')
          .then((response) => {
            app.$store.commit('research', response.data)
            app.$store.commit('loading', false)
            // continue to next route
            this.signout();
          })
          .catch((error) => {
            app.$store.commit('loading', false)
            if (error && error.response) {
              app.$store.commit('loading', false)
            }
          })
    }
  }
}
</script>
