<template>
  <div class="footer section">
      <div class="container">
        <div v-if="logo" class="row">
          <div class="col-md-8 offset-md-2 text-center">
            <img class="logo" src="/img/logo-white.svg" />
          </div>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-2 text-center">
              <hr v-bind:class="{ 'mt-0': !logo }" />
              <ul class="list-inline">
                <li class="list-inline-item"><router-link :to="{'name': 'terms'}">{{ $t("footer_terms_and_conditions") }}</router-link></li>
                <li class="list-inline-item">&bull;</li>
                <li class="list-inline-item"><router-link :to="{'name': 'support'}">{{ $t("footer_support") }}</router-link></li>
                <li class="list-inline-item">&bull;</li>
                <li class="list-inline-item"><a @click="signup">{{ $t("footer_signup") }}</a></li>
                <li class="list-inline-item">&bull;</li>
                <li class="list-inline-item"><a href="https://discover.habital.io/" target="_blank">{{ $t("footer_about") }}</a></li>
              </ul>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "FooterPartial", /* footer does not work */
  props: {
    logo: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
    };
  },
  methods: {
    signup() {
      this.$auth.loginWithRedirect();
    },
  }
};
</script>
