var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.questionList && _vm.questionList.question)?_c('div',{staticClass:"scroll-container"},[_c('div',{staticClass:"scroll-container__header"},[_c('div',{staticClass:"header-illustration",style:({
        backgroundPosition: ((_vm.questionList.percentage_progress) + "% top")
      })}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 offset-md-3"},[_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"uppercase safe-area-menu"},[_vm._v(_vm._s(_vm.questionListTitle))]),_c('div',{staticClass:"question-progressbar"},[_c('u-progress-bar',{attrs:{"numQuestions":_vm.questionList.question_count,"progress":_vm.questionList.percentage_progress}})],1)])])])])]),_c('div',{staticClass:"scroll-container__scroll"},[_c('transition',{attrs:{"name":_vm.transitionName}},[_c('div',{key:_vm.uniqueKey,staticClass:"question-transition-helper"},[_c('div',{staticClass:"scroll-container"},[_c('div',{staticClass:"scroll-container__header"},[(_vm.questionList.question.question_type.slug != 'info')?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 offset-md-2"},[_c('h2',{staticClass:"question-title",domProps:{"innerHTML":_vm._s(_vm.questionList.question.text)}})])])]):_vm._e()]),_c('div',{staticClass:"scroll-container__scroll",class:{ 'question-border': _vm.showQuestionBorder },attrs:{"id":"question-scroll-container"}},[_c('div',{staticClass:"container fill-height"},[_c('div',{staticClass:"row fill-height"},[_c('div',{staticClass:"col-lg-8 offset-lg-2 fill-height padding-nav-prev-next"},[(_vm.questionList.question.question_type.slug == 'float')?[_c('question-number',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"precision":10,"errormsg":_vm.errormsg}})]:(
                      _vm.questionList.question.question_type.slug == 'float2'
                    )?[_c('question-number',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"precision":2,"errormsg":_vm.errormsg}})]:(
                      _vm.questionList.question.question_type.slug == 'float1'
                    )?[_c('question-number',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"precision":1,"errormsg":_vm.errormsg}})]:(
                      _vm.questionList.question.question_type.slug == 'integer'
                    )?[_c('question-number',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"precision":0,"errormsg":_vm.errormsg}})]:(
                      _vm.questionList.question.question_type.slug == 'date'
                    )?[_c('question-date',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"errormsg":_vm.errormsg}})]:(
                      _vm.questionList.question.question_type.slug ==
                      'numeric-slider'
                    )?[_c('question-slider',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"min":_vm.questionList.question.options.min_value,"max":_vm.questionList.question.options.max_value,"errormsg":_vm.errormsg,"pristine":_vm.pristine}})]:(
                      _vm.questionList.question.question_type.slug ==
                      'single-line'
                    )?[_c('question-text',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"max_length":_vm.questionList.question.options.max_length,"errormsg":_vm.errormsg}})]:(
                      _vm.questionList.question.question_type.slug == 'textarea'
                    )?[_c('question-text',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"max_length":_vm.questionList.question.options.max_length,"multiline":true,"errormsg":_vm.errormsg}})]:(
                      _vm.questionList.question.question_type.slug == 'radio'
                    )?[_c('question-radio',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"options":_vm.questionList.question.options.select_options,"textoption":_vm.questionList.question_options.open_text_option,"value_extra":_vm.questionList.response.value_extra,"errormsg":_vm.errormsg},on:{"submitValue":_vm.next}})]:(
                      _vm.questionList.question.question_type.slug == 'sc-icons'
                    )?[_c('question-iconlist',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"errormsg":_vm.errormsg,"options":_vm.questionList.question.options.select_options,"textoption":_vm.questionList.question_options.open_text_option,"value_extra":_vm.questionList.response.value_extra},on:{"submitValue":_vm.next}})]:(
                      _vm.questionList.question.question_type.slug == 'checkbox'
                    )?[_c('question-checkbox',{ref:"questionObject",attrs:{"value":_vm.value,"valid":!_vm.error,"options":_vm.questionList.question.options.select_options,"textoption":_vm.questionList.question_options.open_text_option,"value_extra":_vm.questionList.response.value_extra,"errormsg":_vm.errormsg}})]:(
                      _vm.questionList.question.question_type.slug == 'info'
                    )?[_c('question-info',{ref:"questionObject",attrs:{"data":_vm.questionList.question}})]:_vm._e()],2)])])])])])])],1),_c('div',{staticClass:"navigation-prev-next",class:( _obj = {}, _obj[_vm.bgGradient] = true, _obj )},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 offset-md-2"},[(_vm.questionList.question)?[(_vm.questionList.question.allow_back)?_c('a',{staticClass:"btn-back float-left",on:{"click":_vm.prev}},[_vm._v(_vm._s(_vm.$t('menu_back')))]):_vm._e(),_c('div',{staticClass:"float-right",class:{ loading: _vm.loading }},[_c('div',{staticClass:"btn-spinner-wrapper"},[_c('lottie-animation',{attrs:{"path":"/lottie/mm-spinner-star.json","id":"mm-spinner","autoplay":true,"loop":true}})],1),_c('a',{staticClass:"btn-next",on:{"click":_vm.next}})])]:_vm._e()],2)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }