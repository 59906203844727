<template>
  <div class="text-center">
    <b-form-group :class="{ 'form-group--error': showInvalid }">
      <input
        class="form-control input-date"
        type="text"
        :placeholder="$t('form_date_placeholder')"
        v-model="internalValue"
        @blur="onBlur()"
      />
    </b-form-group>
    <p v-if="errormsg" class="invalid-feedback show text-center mb-1">
      {{ errormsg }}
    </p>
  </div>
</template>

<script>

import EventBus from '@/services/EventBus'

export default {
  name: 'QuestionDate',
  props: {
    value: String,
    errormsg: String,
    invalid: {
      type: Boolean,
      default: false,

    }
  },
  methods: {
    onBlur() {
      let date = this.getNativeValue(this.internalValue) // convert to native format
      if (this.$moment(date).isValid()) {
        // check if valid
        this.showInvalid = false
        this.internalValue = this.$moment(
          this.internalValue,
          this.format
        ).format(this.format)
      } else {
        this.internalValue = ''
        this.showInvalid = true
      }
    },
    getLocalizedValue(v) {
      if (!this.format || !v) return ''
      let date = this.$moment(v, 'YYYY-MM-DD').format(this.format)
      if (this.$moment(date, this.format).isValid()) {
        return date
      }
      return ''
    },

    getNativeValue(v) {
      return this.$moment(v, this.format).format('YYYY-MM-DD')
    },

    getValue() {
      return this.getNativeValue(this.internalValue)
    }
  },
  watch: {
    invalid(val) {
      this.showInvalid = val
    },
    value(val) {
      this.internalValue = this.getLocalizedValue(this.value)
    }
  },
  data() {
    return {
      format: this.$t('form_date_format'),
      showInvalid: false,
      internalValue: this.getLocalizedValue(this.value)
    }
  },
  mounted() {
    this.internalValue = this.getLocalizedValue(this.value)
    // fix textfield position when keyboard in expanded
    EventBus.$on('keyboard-did-show', function () {
      document.getElementById('question-scroll-container').scrollTop = 0
    })
  }
}
</script>
