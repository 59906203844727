<template>
  <div>
    <b-nav class="navbar navbar-expand-lg navbar-dark">
      <b-navbar-brand to="/" class="navbar-brand mr-auto"></b-navbar-brand>
      <b-nav-item v-if="showBack" class="btn-back" @click="back">{{
        $t('menu_back')
      }}</b-nav-item>
      <b-navbar-toggle v-if="!showBack" target="nav_collapse"></b-navbar-toggle>
      <b-nav-item  v-if="showMyData" class="btn-data" @click="openDashboard">{{
          $t('menu_my_data')
        }}</b-nav-item>
      <b-collapse is-nav id="nav_collapse" class="flex-grow-0">
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
        <b-navbar-nav>
          <!-- signed in -->

          <template v-if="isLoggedIn">
            <b-nav-item
              v-if="hasCurrentResearch"
              :to="{ name: 'research-introduction' }"
              class="icon-menu icon-abouthisresearch"
              active
              >{{ $t('menu_about_this_research') }}</b-nav-item
            >
            <b-nav-item
                v-if="research.dashboard_url"
                @click="openDashboard"
                class="icon-menu icon-data"
                active
            >{{ $t('menu_my_data') }}</b-nav-item
            >
            <b-nav-item
              href="https://discover.habital.io/"
              target="_blank"
              class="icon-menu icon-abouthabital"
              >{{ $t('menu_about') }}</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'support' }"
              class="icon-menu icon-support"
              >{{ $t('menu_support') }}</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'account' }"
              class="icon-menu icon-account"
              >{{ $t('menu_account') }}</b-nav-item
            >
          </template>
          <!-- not signed in -->
          <template v-else>
            <b-nav-item
              href="https://discover.habital.io/"
              target="_blank"
              class="icon-menu icon-abouthabital"
              >{{ $t('menu_about') }}</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'support' }"
              class="icon-menu icon-support"
              >{{ $t('menu_support') }}</b-nav-item
            >
            <b-nav-item
              @click="login"
              class="icon-menu icon-account"
              >{{ $t('menu_sign_in') }}</b-nav-item
            >
          </template>
        </b-navbar-nav>
      </b-collapse>
    </b-nav>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { App } from '@capacitor/app';
import {Browser} from "@capacitor/browser";

export default {
  name: 'navigation',
  computed: {
    ...mapState(['researches', 'research', 'loading', 'showNavigation']),
    ...mapGetters(['hasResearchIntroduction', 'activeResearchId', 'user']),
    hasCurrentResearch() {
      return !!(this.activeResearchId > -1 && this.hasResearchIntroduction)
    },
    showBack() {
      return this.$route.meta.showBack ?? false
    },
    isLoggedIn() {
      return Object.entries(this.user).length > 0;
    },
    showMyData() {
      return this.research.dashboard_url && this.$route.name==='dashboard'
    }
  },
  methods: {
    async openDashboard() {
      if(this.research.dashboard_url) {
        await Browser.open({
          url: this.research.dashboard_url,
          toolbarColor: '#FF564D'
        })
      }
    },
    back() {
      if (this.$route.meta.androidDisableBack === true) {
        return
      }
      console.log('exit? ' + (this.$route.meta.androidBackExit === true))
      if (this.$route.meta.androidBackExit === true) {
        App.exitApp()
        return
      }
      if (this.$route.meta.goBackTo) {
        console.log('going back to ' + this.$route.meta.goBackTo)
        this.$router.replace({ name: this.$route.meta.goBackTo })
        return
      }
      console.log('going back')
      this.$router.back()
    },
    login() {
      this.$auth.loginWithRedirect();
    },
  },
  mounted() {
    let _this = this
    App.addListener('backButton', function () {
      _this.back()
    })
  },
  beforeDestroy() {
    App.removeAllListeners()
  }
}
</script>
