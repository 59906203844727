<template>
  <div
      v-if="dashboard"
      class="visual-center"
  >
    <div class="visual-center__content">
      <div class="section section-dashboard" :class="{'section-dashboard-completed':completed}">
        <div class="container">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <div class="dashboard-title mb-5">
                <h1 class="uppercase">{{ dashboard.title }}</h1>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 offset-lg-4 col-md-6 offset-md-3">

              <div class="flask-animation">
                <lottie-animation
                    path="/lottie/mm-dashboard.json"
                    id="mm-progress"
                    :segments="progressSegments"
                ></lottie-animation>
                <lottie-animation
                    v-if="hasNextMilestone"
                    path="/lottie/mm-milestonebar.json"
                    id="mm-milestonebar"
                    :segments="milestoneSegments"
                ></lottie-animation>

                <!-- active milestone -->
                <div v-if="completed" class="milestone-message">
                  <div class="container">
                    <h3 class="uppercase">
                      {{ $t('title_flask_research_completed') }}
                    </h3>
                    <p>{{ $t('text_flask_research_completed') }}</p>
                  </div>
                </div>
                <div
                    v-else-if="dashboard.active_milestone"
                    class="milestone-message"
                >
                  <div class="container">
                    <h4 class="uppercase">
                      {{ $t('title_flask_we_unlocked') }}
                    </h4>
                    <h3 v-html="dashboard.active_milestone.title"></h3>
                    <p v-html="dashboard.active_milestone.message"></p>
                  </div>
                </div>
                <!-- has next milestone -->
                <div
                    v-else-if="dashboard.next_milestone"
                    class="milestone-message"
                >
                  <div class="container">
                    <h3 class="uppercase">{{ $t('title_groups_progress') }}</h3>
                    <h2>{{ measurementsToNextMilestone }}</h2>
                    <p>{{ $t('text_flask_measurements_to_next_milestone') }}</p>
                  </div>
                </div>
                <!-- else show default text -->
                <div v-else class="milestone-message">
                  <div class="container">
                    <h4 class="uppercase">{{ $t('title_flask_default') }}</h4>
                    <p>{{ $t('text_flask_default') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="navigation-dashboard"
        v-if="!completed"
        :class="{ [bgGradient]: true }"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="row no-gutters">
              <div class="col mt-auto mb-auto">
              </div>

              <div class="col-auto">
                <template
                    v-if="dashboard.user_is_away == true || this.samplingsTodo.length < 1"
                >
                  <div class="icon-animated">
                    <lottie-animation
                        path="/lottie/mm-dash-button.json"
                        id="mm-dash-completed"
                        :segments="[153, 154]"
                    ></lottie-animation>
                  </div>
                </template>
                <template v-else>
                  <a @click="onSamplingButtonClick()" class="icon-animated clickable">
                    <span class="badge">{{ samplingsTodoCount }}</span>
                    <lottie-animation
                        path="/lottie/mm-dash-button.json"
                        id="mm-dash-start"
                        :segments="[101, 135]"
                        :autiplay="true"
                        :loop="true"
                    ></lottie-animation>
                  </a>
                </template>
              </div>
              <div class="col">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ULModal ref="modalNotWorking">
      <h4>{{ $t('title_not_working') }}</h4>
      <img src="/img/illustrations/notworking.svg"/>
      <p>{{ $t('text_not_working') }}</p>
      <button @click="setNotWorking" class="btn btn-outline-white btn-sm">
        {{ $t('button_cancel_measurements') }}
      </button>
    </ULModal>

    <ULModal v-if="dashboard.active_milestone" ref="modalMilestone">
      <img src="/img/illustrations/badge.svg"/>
      <h4 v-html="dashboard.active_milestone.title"></h4>
      <p v-html="dashboard.active_milestone.message"></p>
    </ULModal>
  </div>
</template>

<script>
import LottieAnimation from '@/components/shared/LottieAnimation'
import store from '@/store'
import EventBus from '@/services/EventBus'
import RouteHelper from '@/services/RouteHelper'
import ULModal from '@/components/shared/ULModal'
import {mapGetters, mapMutations} from 'vuex';

export default {
  store,
  name: 'Dashboard',
  components: {LottieAnimation, ULModal},
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },
  beforeRouteEnter(to, from, next) {
    var app = this
    store
        .dispatch('getResearchDashboard')
        .then((response) => {
          next((vm) => {
            vm.onLoad(response.data)
          })
        })
        .catch((error) => {
          next((vm) => {
            vm.onError(error)
          })
        })
  },
  computed: {
    ...mapGetters(['samplingsTodoCount', 'samplingsTodo', 'needToSeeFlaskAnimation', 'research']),
    timerFrame() {
      let frames = 100
      let percentage =
          100 -
          (this.dashboard.next_measurement.countdown /
              this.dashboard.next_measurement.scale_max) *
          100
      return Math.max(Math.round((frames / 100) * percentage), 1)
    },
    progressSegments() {
      // animations start on frame 1
      let progress = 0;
      if (this.dashboard.total_filled_sampling && this.dashboard.total_expected_sampling) {
        progress = Math.min(1,
            this.dashboard.total_filled_sampling /
            this.dashboard.total_expected_sampling
        )
      }
      let frameTo = Math.min(62, 31 + Math.round((31 + 1) * progress))
      if (this.needToSeeFlaskAnimation) {
        return [0, frameTo]
      } else {
        return [frameTo - 1, frameTo]
      }
    },
    milestoneSegments() {
      var milestone = this.dashboard.next_milestone
      if (milestone) {
        let numFrames = 30
        let progress = Math.min(
            1,
            milestone.research_amount / this.dashboard.total_expected_sampling
        )
        // animations start on frame 1
        let frame = Math.max(1 + Math.ceil(progress * numFrames), 1)
        // let frame = 1 + Math.max(Math.ceil(progress * numFrames), 1); // incorrect?
        return [frame - 1, frame]
      }
      return [0, 1]
    },
    measurementsToNextMilestone() {
      return (
          this.dashboard.next_milestone.research_amount -
          this.dashboard.total_filled_sampling
      )
    },
    hasNextMilestone() {
      return this.dashboard.next_milestone && this.dashboard.next_milestone.id
    }
  },
  data() {
    return {
      dashboard: {},
      timer: null,
      completed: false,
      bgGradient: '',
      missedSamplings: 0
    }
  },
  methods: {
    ...mapMutations([
      'setSamplingsToDo'
    ]),
    onSamplingButtonClick() {
      if (this.samplingsTodo.length === 1 && this.samplingsTodo[0].compulsory === true) {
        const location = RouteHelper.getNextSamplingRoute(this.samplingsTodo[0]);
        this.$router.push(location)
            .catch(e => console.log(e));
        return;
      }
      this.$router.push({name: 'sampling-select'});
    },
    onLoad(data) {
      this.dashboard = data
      this.setSamplingsToDo(this.dashboard.samplings_to_do ?? []);
      store.commit('loading', false)

      this.completed =
          this.dashboard.research_finished || this.dashboard.user_finished

      // get show_results (button) from research
      if (store.state.research) {
        this.dashboard.show_results = store.state.research.show_results
      }

      // count missed todays samplings
      this.missedSamplings = 0

      var app = this
      // update dashboard every 45 second so we can update the minute countdown
      this.timer = setTimeout(function () {
        store
            .dispatch('getResearchDashboard')
            .then((response) => {
              app.onLoad(response.data)
            })
            .catch((error) => {
              app.onError(error)
            })
      }, 45000)

      // show achievment and set local storage
      setTimeout(function () {
        var hasSeenMilestoneId = window.localStorage.getItem(
            'hasSeenMilestoneId'
        )
        if (
            app.$refs.modalMilestone &&
            app.dashboard.active_milestone &&
            app.dashboard.active_milestone.id != hasSeenMilestoneId
        ) {
          app.$refs.modalMilestone.open()
          window.localStorage.setItem(
              'hasSeenMilestoneId',
              app.dashboard.active_milestone.id
          )
        }
      }, 3500)
    },

    ///////////////////////////////////////////////////////////////////////////////////////
    // onError
    //
    ///////////////////////////////////////////////////////////////////////////////////////
    onError(error) {
      if ((error && error.response) || !error) {
        EventBus.$emit('on-server-error', error)
      }
      store.commit('loading', false)
    },
    ///////////////////////////////////////////////////////////////////////////////////////
    // setNotWorking
    //
    ///////////////////////////////////////////////////////////////////////////////////////
    setNotWorking() {
      store
          .dispatch('notWorking')
          .then((response) => {
            this.$refs.modalNotWorking.close()
            this.dashboard.user_is_away = true
            store.commit('loading', false)
          })
          .catch((error) => {
            this.onError(error)
          })
    }
  },

  mounted() {
    let app = this

    this.bgGradient = 'gradient-' + this.$route.meta.bg;
    // set needToSeeFlaskAnimation to false after animation has played
    if (this.needToSeeFlaskAnimation) {
      setTimeout(function () {
        store.state.needToSeeFlaskAnimation = false
      }, 4000)
    }

    /**
     * always refresh data when returning from inactive app state
     */
    EventBus.$on('app-state-change', (state) => {
      if (state.isActive) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
        store
            .dispatch('getResearchDashboard')
            .then((response) => {
              app.onLoad(response.data)
            })
            .catch((error) => {
              app.onError(error)
            })
      }
    })
  }
}
</script>
