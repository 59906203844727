import Vue from 'vue'
import Router from 'vue-router'


import Home from '@/views/Home';

import store from '@/store';
import i18n from "@/services/I18n.js";

import Support from '@/views/Support';
import ResearchSelect from '@/views/research/ResearchSelect';
import QuestionList from '@/views/research/QuestionList';
import ResultList from '@/views/research/ResultList';

import Dashboard from '@/views/research/Dashboard';
import ResearchJoin from '@/views/research/ResearchJoin';

import Account from '@/views/account/Account';

import DefaultContent from '@/views/DefaultContent';
import TermsConditions from '@/views/TermsConditions';
import CookieStatement from '@/views/CookieStatement';
import LandingPage from '@/views/LandingPage';
import IntroOutro from '@/views/research/IntroOutro';
import SamplingCompleted from '@/views/research/SamplingCompleted';
import SamplingSelect from '@/views/research/SamplingSelect';
import RouteHelper from "../services/RouteHelper";


Vue.use(Router)

const router = new Router({
    i18n,
    store,
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                bg: 5,
                autoRoute: true,
                androidBackExit: true,
                menuTakesSpace: false
            },
        }, {
            path: '/landing/:slug?',
            name: 'landing',
            component: LandingPage,
            meta: {
                bg: 4,
            },
        }, {
            path: '/support',
            name: 'support',
            component: Support,
            meta: {
                bg: 1,
                bgShape: true,
                showBack: true,
            },
        }, {
            path: '/terms',
            name: 'terms',
            component: TermsConditions,
            props: {
                title: 'title_terms_and_services',
                content: 'text_terms_and_services'
            },
            meta: {
                bg: 1,
                showBack: true,
            },
        }, {
            path: '/cookie-statement',
            name: 'cookie-statement',
            component: CookieStatement,
            props: {
                title: 'title_cookie_statement',
                content: 'text_cookie_statement'
            },
            meta: {
                bg: 1,
            },
            ////////////////////////////////////////////////////////////////////
            // account
            ////////////////////////////////////////////////////////////////////
        }, {
            path: '/account',
            name: 'account',
            component: Account,
            meta: {
                auth: true,
                bg: 2,
                showBack: true,
            },

            ////////////////////////////////////////////////////////////////////
            // research
            ////////////////////////////////////////////////////////////////////
        }, {
            path: '/research',
            name: 'research',
            component: ResearchSelect,
            meta: {
                auth: true,
                autoRoute: false,
                bg: 9,
                showBack: true
            },
        }, {
            path: '/research/introduction',
            name: 'research-introduction',
            component: IntroOutro,
            meta: {
                auth: true,
                autoRoute: false,
                bg: 4,
                hideMenu: true,
                menuTakesSpace: false
            },
            props: (route) => ({
                items: store.state.research.intro_texts
            }),
            beforeEnter: (to, from, next) => {
                var r = store.state.research;
                r.has_seen_intro = true;
                store.commit("research", r);
                next();
            }
        }, {
            path: '/research/:questionListId/sampling/:samplingId',
            name: 'sampling',
            component: QuestionList,
            props: true,
            meta: {
                auth: true,
                autoRoute: false,
                bg: 3,
                hideMenu: false,
                menuTakesSpace: false,
                androidDisableBack: true
            },
            beforeEnter: (to, from, next) => {
                if (!to.params.samplingData) {
                    const samplingTodo = store.getters.samplingTodo(to.params);
                    if (samplingTodo) {
                        next(RouteHelper.getNextSamplingRoute(samplingTodo))
                    } else {
                        next({name: 'dashboard'})
                    }
                } else {
                    next();
                }
            }
        }, {
            path: '/research/:questionListId/sampling/:samplingId/samplingTime/:samplingTimeId',
            name: 'sampling-time',
            component: QuestionList,
            props: true,
            meta: {
                auth: true,
                autoRoute: false,
                bg: 3,
                hideMenu: false,
                menuTakesSpace: false,
                androidDisableBack: true
            },
            beforeEnter: (to, from, next) => {
                if (!to.params.samplingData) {
                    const samplingTodo = store.getters.samplingTodo(to.params);
                    if (samplingTodo) {
                        next(RouteHelper.getNextSamplingRoute(samplingTodo))
                    } else {
                        next({name: 'dashboard'})
                    }
                } else {
                    next();
                }
            }
        }, {
            path: '/research/outro',
            name: 'outro',
            component: IntroOutro,
            meta: {
                auth: true,
                autoRoute: false,
                bg: 4,
                hideMenu: true
            },
            props: (route) => ({
                items: store.state.research.outro_texts,
                showNavigation: true
            }),
            beforeEnter: (to, from, next) => {
                var r = store.state.research;
                r.has_seen_outro = true;
                store.commit("research", r);
                next();
            }
        }, {
            path: '/research/sampling/introduction',
            name: 'sampling-introduction',
            component: IntroOutro,
            meta: {
                auth: true,
                autoRoute: false,
                bg: 4,
                hideMenu: true
            },
            props: true
        }, {
            // select missed sampling
            path: '/research/sampling/select',
            name: 'sampling-select',
            component: SamplingSelect,
            meta: {
                auth: true,
                autoRoute: false,
                bg: 3,
            },
        }, {
            path: '/research/sampling/outro',
            name: 'sampling-outro',
            component: IntroOutro,
            meta: {
                auth: true,
                autoRoute: false,
                bg: 4,
                hideMenu: true
            },
            props: true
        }, {
            path: '/research/sampling-completed',
            name: 'sampling-completed',
            component: SamplingCompleted,
            meta: {
                auth: true,
                autoRoute: false,
                bg: 5,
                hideMenu: true,
                goBackTo: 'home',
            },
            props: (route) => ({
                items: [{
                    title: i18n.t('title_sampling_complete'),
                    body: i18n.t('text_sampling_complete'),
                }]
            }),

            beforeEnter: (to, from, next) => {
                store.state.needToSeeFlaskAnimation = true;
                store.state.needToSeeSamplingCompleted = false;
                next();
            }
        }, {
            path: '/research/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                auth: true,
                autoRoute: true,
                bg: 5,
                androidBackExit: true,
                menuTakesSpace: false,
            }
        }, {
            path: '/research/results',
            name: 'results',
            component: ResultList,
            meta: {
                auth: true,
                autoRoute: false,
                bg: 5,
                bgShape: true,
                menuTakesSpace: false,
            }
        }, {

            path: '/research/join',
            name: 'research-join',
            component: ResearchJoin,
            meta: {
                auth: true,
                bg: 9,
                showBack: true,
            }
        }
        , {
            path: '/404',
            name: '404',
            component: DefaultContent,
            props: {
                title: 'title_page_not_found',
                content: 'text_page_not_found'
            },
            meta: {
                bg: 1,
            },
        }
        , {
            path: '/500',
            name: '500',
            component: DefaultContent,
            props: {
                title: 'title_server_error',
                content: 'text_server_error'
            },
            meta: {
                bg: 1,
            },
        },
        {path: '*', redirect: '/404'},
    ]
})

export default router;
