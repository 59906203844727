import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

/* -----------------------------------------------------------------
 * i18n localization
 * https://docs.google.com/spreadsheets/d/19V63AM1z8xn5PxLiXp10jdI5CbxCmePaDWTaO1m6T-4/edit?ouid=114689204894213192475&usp=sheets_home&ths=true
 * https://www.csvjson.com/csv2json
 * -------------------------------------------------------------- */

const defaultLang = 'en';
const i18nConfig = {
    locale: defaultLang,
    fallbackLocale: defaultLang,
    messages: {}
};
i18nConfig.messages['en'] = require('../lang/en.json');
i18nConfig.messages['fr'] = require('../lang/fr.json');
i18nConfig.messages['nl'] = require('../lang/nl.json');
export default new VueI18n(i18nConfig);

