<template>
  <div v-if="resultList" class="scroll-container pb-0 section section-bg-shape">
    <!-- start header -->
    <div class="scroll-container__header">
      <div class="container">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <div class="text-center">

              <h3 class="uppercase safe-area-menu mt-5">
                {{ $t('title_results') }} - {{ research.title }}
              </h3>
              <div class="pb-3">
                <u-progress-bar
                  :numQuestions="resultCount"
                  :progress="(resultPage / resultCount) * 100"
                />
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- end header -->
    <!-- start main scroll area -->
    <div class="scroll-container__scroll">
      <transition :name="transitionName">
        <div :key="uniqueKey" class="question-transition-helper">
          <div class="scroll-container">
            <!-- question header -->
            <div class="scroll-container__header">
              <div class="container">
                <div class="row">
                  <div class="col-md-8 offset-md-2">
                    <h5
                      v-show="resultList.type === 'rating'"
                      class="mb-1 text-center"
                    >
                      {{ $t('title_best_rated_place_for') }}
                    </h5>
                    <h2 class="question-title">{{ resultList.title }}</h2>
                  </div>
                </div>
              </div>
            </div>

            <!-- question scroll -->
            <div
              class="scroll-container__scroll padding-nav-prev-next question-border"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-8 offset-md-2">
                    <div class="result-list mt-5">
                      <template v-if="resultList.type === 'rating'">
                        <h4 class="uppercase text-center">
                          {{ $t('title_you') }}
                        </h4>
                        <div
                          v-for="(item, index) in resultList.responses"
                          :key="index"
                        >
                          <div class="result-row">
                            <div
                              :class="[
                                'rating ' + getRatingColorClass(item.value)
                              ]"
                            >
                              {{
                                item.value
                                  | forceDecimal($t('form_decimal_separator'))
                              }}
                            </div>
                            <div class="answer">
                              <div>{{ item.text }}</div>
                            </div>
                            <div
                              v-if="item.group_best"
                              class="arrow_box arrow_box_group"
                            >
                              {{ $t('title_group_best') }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <h4 class="uppercase text-center">
                          {{ $t('title_group') }}
                        </h4>
                        <div
                          v-for="(item, index) in resultList.responses"
                          :key="index"
                        >
                          <div class="result-row">
                            <!-- <div class="index">{{index + 1}}</div> -->
                            <div class="percentage">{{ item.value }}%</div>
                            <div class="answer">
                              <div>{{ item.text }}</div>
                            </div>
                            <div v-if="item.own" class="arrow_box">
                              {{ $t('title_you') }}<br />
                              {{ item.own }}%
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end question scroll -->
          </div>
        </div>
      </transition>
    </div>
    <!-- end main scroll area -->

    <div class="navigation-prev-next" :class="{ [bgGradient]: true }">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <template v-if="survey && resultList">
              <a @click="prev" class="btn-back float-left">{{
                $t('menu_back')
              }}</a>
              <div class="float-right" :class="{ loading: loading }">
                <div class="btn-spinner-wrapper">
                  <lottie-animation
                    path="/lottie/mm-spinner-star.json"
                    id="mm-spinner"
                    :autoplay="true"
                    :loop="true"
                  ></lottie-animation>
                </div>
                <a @click="next" class="btn-next"></a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="error">
    <div class="section question">
      <div class="container">
        <div class="question-body text-center">
          {{ errormsg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import store from '@/store'
import RouteHelper from '@/services/RouteHelper'
import LottieAnimation from '@/components/shared/LottieAnimation'
import UProgressBar from '@/components/shared/UProgressBar'
import EventBus from "@/services/EventBus";

export default {
  store,
  name: 'ResultList',
  components: {
    LottieAnimation,
    UProgressBar
  },
  data() {
    return {
      data: [],
      resultList: [],
      resultCount: 1,
      resultPage: 0,
      error: false,
      errormsg: '',
      value: null,
      uniqueKey: 0,
      transitionName: 'next',
      bgGradient: ''
    }
  },
  computed: {
    ...mapState(['researches', 'research', 'survey', 'loading'])
  },
  filters: {
    forceDecimal(value, decimalSeparator) {
      if (value !== null) {
        return value.toString().split('.').join(decimalSeparator)
      }
      return ''
    }
  },
  beforeRouteEnter(to, from, next) {
    var app = this
    store
      .dispatch('getResults', 1)
      .then((response) => {
        next((vm) => {
          vm.onLoad(response.data)
        })
      })
      .catch((error) => {
        next((vm) => {
          vm.onError(error)
        })
      })
  },
  methods: {
    onLoad(data) {
      this.data = data
      this.resultPage = 0
      this.resultList = this.data[0]
      this.resultCount = this.data.length
      this.error = false
      this.errormsg = ''

      store.commit('loading', false)
    },

    ///////////////////////////////////////////////////////////////////////////////////////
    // onError
    //
    ///////////////////////////////////////////////////////////////////////////////////////
    onError(error) {
      this.error = error
      if (error && error.response) {
        this.errormsg = error.response.data.error_message || 'unknow error'
      }
      store.commit('loading', false)
    },
    ///////////////////////////////////////////////////////////////////////////////////////
    // prev
    //
    ///////////////////////////////////////////////////////////////////////////////////////
    prev() {
      if (this.resultPage - 1 < 0) {
        this.$router.push({ name: 'dashboard' }).catch(() => {})
        return
      }

      this.transitionName = 'prev'
      this.resultPage--
      this.uniqueKey++ // needed to refresh v-model bindings
      this.resultList = this.data[this.resultPage]
    },
    ///////////////////////////////////////////////////////////////////////////////////////
    // next
    //
    ///////////////////////////////////////////////////////////////////////////////////////
    next() {
      if (this.resultPage + 1 >= this.resultCount) {
        this.$router.push({ name: 'dashboard' }).catch(() => {})
        return
      }

      this.transitionName = 'next'
      this.uniqueKey++ // needed to refresh v-model bindings
      this.resultPage++
      this.resultList = this.data[this.resultPage]
    },
    ///////////////////////////////////////////////////////////////////////////////////////
    // next
    //
    ///////////////////////////////////////////////////////////////////////////////////////
    getRatingColorClass: function (rating) {
      if (rating == null) return ''
      if (rating >= 7.0) return 'green'
      if (rating >= 5.0) return 'orange'
      if (rating >= 0.0) return 'red'
      return ''
    }
  },
  mounted() {
    this.bgGradient = 'gradient-' + this.$route.meta.bg;

    const app = this;
    EventBus.$on('swipe-left', function() {
      app.next();
    });
    
    EventBus.$on('swipe-right', function() {
      app.prev();
    });
  }
}
</script>
