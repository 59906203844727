<template>
  <div class="row fill-height">
    <div class="col-md-8 offset-md-2 fill-height">
      <div class="visual-center">
        <div class="scroll-container__content">
          <b-form-group
            :class="[
              !isValid ? 'form-group--error' : '',
              options.length <= 4 ? 'icon-list-blocks' : 'icon-list-compact'
            ]"
            class="icon-list"
          >
            <b-form-radio-group
              class="custom-controls-stacked"
              stacked
              v-model="internalValue"
              name="radio"
            >
              <b-form-radio
                class="icon"
                v-for="item in options"
                :key="item.row"
                @change="submitValue"
                :value="item.option"
                :class="[
                  item.icon,
                  internalValue == item.option ? 'selected' : ''
                ]"
              ><span v-html="item.text"></span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <p v-if="errormsg" class="invalid-feedback show text-center mb-1">
            {{ errormsg }}
          </p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../services/EventBus'

export default {
  name: 'QuestionIconlist',
  props: {
    options: Array,
    valid: Boolean,
    value: [Number, String, Array],
    errormsg: String
  },
  methods: {
    getValue() {
      return this.internalValue
    },
    submitValue() {
      //in order for this.internalValue to be updated first,
      // the submitValue event needs to emitted 1 tick later
      setTimeout(function () {
        EventBus.$emit('submit-value')
      })
    }
  },
  watch: {
    valid(v) {
      this.isValid = v
    },
    value(v) {
      this.internalValue = v
    }
  },
  data() {
    return {
      isValid: this.valid,
      internalValue: this.value
    }
  },
  mounted() {
    this.internalValue = this.value
  }
}
</script>
