import {Capacitor} from '@capacitor/core';
import {PushNotifications} from '@capacitor/push-notifications';
import RouteHelper from "./RouteHelper";

export default class FcmService {

    vueRouter = null;
    vueStore = null;

    initPush(router, store) {
        this.vueRouter = router;
        this.vueStore = store;
        if (Capacitor.getPlatform() !== 'web') {
            this.registerPush();
        }
        if (Capacitor.getPlatform() === 'android') {
            this.initAndroidNotificationChannel();
        }
    }

    initAndroidNotificationChannel() {
        PushNotifications.createChannel({
            id: 'samplings',
            name: 'Sampling notifications',
            description: 'Receive notifications when new samplings are ready',
            importance: 5,
            visibility: 1,
            lights: true,
            vibration: true
        });
        PushNotifications.createChannel({
            id: 'milestones',
            name: 'Milestone notifications',
            description: 'Receive notifications when a new milestone has been reached',
            importance: 5,
            visibility: 1,
            lights: true,
            vibration: true
        });
    }

    registerPush() {
        PushNotifications.requestPermissions().then((permissionStatus) => {
            console.log('requestPermissions', JSON.stringify(permissionStatus))
            if (permissionStatus.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
            } else {
                // No permission for push granted
            }
        });

        PushNotifications.addListener(
            'registration',
            (token) => {
                console.log('My token: ' + JSON.stringify(token));
                this.vueStore.commit("setFcmToken", token.value);
                if (this.vueStore.getters.user) {
                    this.vueStore.dispatch('updateUser', {})
                        .then(response => console.log('User updated: ', JSON.stringify(response)));
                }
            }
        );

        PushNotifications.addListener('registrationError', (error) => {
            console.log('Error: ' + JSON.stringify(error));
        });

        PushNotifications.addListener(
            'pushNotificationReceived',
            async (notification) => {
                const data = notification.data;
                const type = data.type || 'default';
                console.log('Push received: type: ' + type + ', ' + JSON.stringify(notification));
                if (type === 'sampling') {
                    console.log('data', JSON.stringify(data))
                    this.vueStore.dispatch('getSamplingsTodoToday').then(response => {
                        this.vueStore.commit('loading', false);
                        this.vueStore.commit('setSamplingsToDo', response.data);
                    });
                }
            }
        );

        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification) => {
                const data = notification.notification.data;
                const type = data.type || 'default';
                console.log('Action performed: type: ' + type + ', ' + JSON.stringify(notification.notification));
                if (type === 'sampling') {
                    if (!this.vueStore.getters.samplingsTodoCount) {
                        console.log('no samplingsTodo, app might need to boot first');
                        //app might not have booted up completely yet, main.js not yet run
                        this.vueStore.commit('setSamplingNotification', data);
                    } else {
                        const sampling = this.vueStore.getters.samplingTodo(data);
                        if (sampling) {
                            this.gotoSampling(sampling)
                        } else {
                            //sampling might be newly available
                            this.vueStore.dispatch('getSamplingsTodoToday').then(response => {
                                this.vueStore.commit('setSamplingsToDo', response.data);
                                const sampling = this.vueStore.getters.samplingTodo(data);
                                if (sampling) {
                                    this.gotoSampling(sampling)
                                }
                            });
                        }
                    }
                }
            }
        );
    }

    gotoSampling(sampling) {
        const route = RouteHelper.getNextSamplingRoute(sampling);
        console.log('route', JSON.stringify(route))
        if (route) {
            this.vueRouter.push(route);
        }
    }
}
