<template>

  <div class="scroll-container">
    <div class="scroll-container__scroll">

<div class="visual-center">
<div class="visual-center__center">



      <div class="lottie-background">

          <div class="padding-nav-prev-next">
            <div class="container">
              <div class="row">
                <div class="col-md-8 offset-md-2">
                    <lottie-animation path="/lottie/mm-shapeshifter.json" :autoplay="true" :loop="true" id="mm-shapeshifter" class="mm-shapeshifter"></lottie-animation>

                    <div class="slider-container">
                      <carousel v-if="items" v-model="slide" :items="items"></carousel>
                    </div>

                </div>
              </div>
            </div>

        </div>
</div>
</div>

  </div>
    </div>
      <div v-if="showNavigation" class="navigation-prev-next" :class="{[bgGradient]:true}">
        <div class="container">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <a @click="next" class="btn-next"></a>
            </div>
          </div>
        </div>
      </div>



    </div>
</template>

<script>

///////////////////////////////////////////////////////////////////////////////////////
// IntroOutro
// used for all intro and outro views (Research, Survey, Epx. sampling)
///////////////////////////////////////////////////////////////////////////////////////

import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import RouteHelper from "@/services/RouteHelper";
import Carousel from "@/components/shared/Carousel";
import LottieAnimation from "@/components/shared/LottieAnimation";

export default {
  name: "IntroOutro",
  components: {Carousel, LottieAnimation},
  data() {
    return {
      slide: 0,
      bgGradient: ''
    };
  },
  props: {
      items: [Array, Object],
      samplingData: {
        type: Object,
        default: null
      },
      showNavigation: {
        type:Boolean,
        default:true
      }
  },
  computed: {
    ...mapState(["researches", "research", "loading"])
  },

  methods: {
    next() {
      if(this.slide + 1 < this.items.length){
        this.slide++;
      } else {
        const route = this.samplingData
            ? RouteHelper.getNextSamplingRoute(this.samplingData)
            : RouteHelper.getNextRoute();
        if (route) {
          this.$router.push(route).catch(() => {});
        }
      }
    },
  },
  mounted() {

    this.bgGradient = 'gradient-' + this.$route.meta.bg;
  }
};
</script>
