<template>

  <div class="visual-center">
    <div class="visual-center__content">

      <div class="section" v-if="show">
        <div class="container">
          <div class="row">
            <div class="col col-md-6 offset-md-3 text-center">
              <h1>{{ $t('title_select_sampling') }}</h1>
              <button
                  v-for="item in samplingsTodo"
                  :key="item.samplingId + '_' + item.samplingTimeId"
                  class="btn btn-outline-white-select"
                  @click="selectSampling(item)" v-html="item.samplingTitle"
              >

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import RouteHelper from '@/services/RouteHelper'
import store from '@/store'

export default {
  store,
  name: 'SamplingSelect',
  components: {},
  data() {
    return {
      show: true,
      post: null,
      error: null,
      missedSamplings: []
    }
  },
  beforeRouteEnter(to, from, next) {
    store
        .dispatch('samplingsTodoToday')
        .then((response) => {
          store.commit('loading', false)
          store.commit('setSamplingsToDo', response.data ?? []);
          if(store.getters.activeSampling) {
            next(RouteHelper.getNextSamplingRoute(store.getters.activeSampling))
          } else if(store.getters.samplingsTodo.length === 0) {
            next({name: 'dashboard'})
          } else {
            next()
          }
        })
        .catch((error) => {
          if (error && error.response) {
            this.error = error
          }
          store.commit('loading', false)
        })
  },

  computed: {
    ...mapState(['researches', 'research', 'loading']),
    ...mapGetters(['activeResearchId', 'samplingsTodo'])
  },
  methods: {
    selectSampling(samplingData) {
      this.$router.push(RouteHelper.getNextSamplingRoute(samplingData))
    }
  }
}
</script>
