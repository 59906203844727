<template>

    <div class="visual-center">
      <div class="visual-center__content">

        <div class="lottie-background sampling-completed">
          <div class="section pb-0 pt-0">
            <div class="container">
              <div class="row">
                <div class="col-md-8 offset-md-2 text-center">
                  <lottie-animation
                    path="/lottie/mm-shapeshifter.json"
                    :autoplay="true"
                    :loop="true"
                    id="mm-shapeshifter"
                    class="mm-shapeshifter"
                  ></lottie-animation>

                  <div class="slider-container">
                    <carousel
                      v-if="items"
                      v-model="slide"
                      :items="items"
                    ></carousel>
                  </div>

                  <div
                    class="completed-animation-container"
                    @click="startAnimation()"
                  >
                    <lottie-animation
                      path="/lottie/mm-completed.json"
                      :segments="dropSegments"
                      id="mm-completed"
                      class="mm-completed"
                    ></lottie-animation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
///////////////////////////////////////////////////////////////////////////////////////
// IntroOutro
// used for all intro and outro views (Research, Survey, Epx. sampling)
///////////////////////////////////////////////////////////////////////////////////////

import { mapState } from 'vuex'
import Carousel from '@/components/shared/Carousel'
import LottieAnimation from '@/components/shared/LottieAnimation'
import { App } from '@capacitor/app';

export default {
  name: 'SamplingCompleted',
  components: { Carousel, LottieAnimation },
  data() {
    return {
      slide: 0,
      animationBeginFrame: 0,
      animationEndFrame: 15
    }
  },
  props: {
    items: [Array, Object],
    showNavigation: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(['researches', 'research', 'loading']),
    dropSegments() {
      return [this.animationBeginFrame, this.animationEndFrame]
    }
  },
  methods: {
    startAnimation() {
      let app = this
      this.animationBeginFrame = 15
      this.animationEndFrame = 30
      setTimeout(function () {
        app.$router.push({ name: 'dashboard' }).catch(() => {})
      }, 750)
    }
  },
  mounted() {
    let _this = this
    App.addListener('backButton', function () {
      _this.startAnimation()
    })
  },
  beforeDestroy() {
    App.removeAllListeners()
  }
}
</script>
