<template>
  <div class="row">
    <div class="col-md-8 offset-md-2 text-center">
      <b-form-group :class="{ 'form-group--error': !isValid }">
        <b-input
          v-if="!multiline"
          class="form-control input-singleline"
          type="text"
          v-model="internalValue"
          :maxlength="maxlength"
        />
        <b-textarea
          v-else
          class="form-control input-multiline"
          type="text"
          v-model="internalValue"
          :maxlength="maxlength"
        />
      </b-form-group>

      <p v-if="errormsg" class="invalid-feedback show text-center mb-1">
        {{ errormsg }}
      </p>
    </div>
  </div>
</template>     

<script>

import EventBus from '@/services/EventBus'

export default {
  name: 'QuestionText',
  props: {
    valid: Boolean,
    multiline: Boolean,
    value: String,
    maxlength: Number,
    errormsg: String
  },
  methods: {
    getValue() {
      return this.internalValue
    }
  },
  watch: {
    valid(v) {
      this.isValid = v
    },
    value(v) {
      this.internalValue = this.value
    }
  },
  data() {
    return {
      isValid: this.valid,
      internalValue: this.value
    }
  },
  mounted() {
    this.internalValue = this.value


    // fix textfield position when keyboard in expanded
    EventBus.$on('keyboard-did-show', function () {
      document.getElementById('question-scroll-container').scrollTop = 0
    })
  }
}
</script>