<template>
  <div class="visual-center center-1-3">
    <div class="scroll-container__content">
      <div
          class="text-center slider-container"
          :class="{ pristine: isPristine }"  @mousedown="onChange" @touchstart="onChange"
      >
        <div class="form-group" :class="{ 'form-group--error': !isValid }">
          <span class="slider-mix-max">{{ min }}</span>
          <b-form-slider

            ref="sliderComponent"
            v-model="internalValue"
            :min="min"
            :max="max"
          />
          <span class="slider-mix-max">{{ max }}</span>
        </div>
      </div>
      <p v-if="errormsg" class="invalid-feedback show text-center mb-1">
        {{ errormsg }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionSlider',
  props: {
    min: Number,
    max: Number,
    valid: Boolean,
    value: Number,
    errormsg: String,
    pristine: Boolean
  },
  methods: {
    getValue() {
      if (this.isPristine) return null
      if (this.internalValue == null) return this.value
      return this.internalValue
    },
    onChange() {
      this.isPristine = false
    }
  },
  watch: {
    valid(v) {
      this.isValid = v
    },
    value(v) {
      this.internalValue = this.value
    }
  },
  data() {
    return {
      isValid: this.valid,
      internalValue: this.value,
      isPristine: this.pristine
    }
  },
  mounted() {
    this.internalValue = this.value
    this.isPristine = this.pristine
  }
}
</script>
