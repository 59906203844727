export default class Sampling {
    constructor(questionList, sampling, samplingTime) {

        const milestoneContributions =
            (questionList.options !== null)
                ? questionList.options.milestone_contributions ?? false
                : false;

        this.questionListId = parseInt(questionList.id);
        this.questionListTitle = questionList.title;
        this.milestoneContributions = milestoneContributions;
        this.samplingId = parseInt(sampling.id);
        this.samplingTimeId = samplingTime ? parseInt(samplingTime.id) : null;
        this.samplingTitle = sampling.title;
        this.introTexts = questionList.intro_texts;
        this.outroTexts = questionList.outro_texts;
        this.dataSetStatus = samplingTime ? samplingTime.data_set_status : sampling.data_set_status;
        this.needsToSeeIntro = this.dataSetStatus === 'none' && this.introTexts.length > 0;
        this.needsToSeeOutro = this.outroTexts.length > 0;
    }

    matchesParams(params) {
        if (!params.questionListId || !params.samplingId) {
            return false;
        }
        let match = this.questionListId === parseInt(params.questionListId)
            && this.samplingId === parseInt(params.samplingId);

        if (params.samplingTimeId === null) {
            return match && this.samplingTimeId === null;
        }
        return match && this.samplingTimeId === parseInt(params.samplingTimeId);
    }

    isOpen() {
        return this.dataSetStatus === 'open';
    }
}
