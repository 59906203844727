<template>
  <div v-if="questionList && questionList.question" class="scroll-container">
    <!-- start header -->
    <div class="scroll-container__header">
      <div
        class="header-illustration"
        :style="{
          backgroundPosition: `${questionList.percentage_progress}% top`
        }"
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <div class="text-center">
              <h3 class="uppercase safe-area-menu">{{ questionListTitle }}</h3>
              <div class="question-progressbar">
                <u-progress-bar
                  :numQuestions="questionList.question_count"
                  :progress="questionList.percentage_progress"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end header -->
    <!-- start main scroll area -->
    <div class="scroll-container__scroll">
      <transition :name="transitionName">
        <div :key="uniqueKey" class="question-transition-helper">
          <div class="scroll-container">
            <!-- question header -->
            <div class="scroll-container__header">
              <div
                class="container"
                v-if="questionList.question.question_type.slug != 'info'"
              >
                <div class="row">
                  <div class="col-md-8 offset-md-2">
                    <h2 class="question-title" v-html="questionList.question.text"></h2>
                  </div>
                </div>
              </div>
            </div>

            <!-- question scroll -->
            <div
              class="scroll-container__scroll"
              id="question-scroll-container"
              :class="{ 'question-border': showQuestionBorder }"
            >
              <div class="container fill-height">
                <div class="row fill-height">
                  <div
                    class="col-lg-8 offset-lg-2 fill-height padding-nav-prev-next"
                  >
                    <template
                      v-if="questionList.question.question_type.slug == 'float'"
                    >
                      <question-number
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :precision="10"
                        :errormsg="errormsg"
                      />
                    </template>
                    <template
                      v-else-if="
                        questionList.question.question_type.slug == 'float2'
                      "
                    >
                      <question-number
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :precision="2"
                        :errormsg="errormsg"
                      />
                    </template>
                    <template
                      v-else-if="
                        questionList.question.question_type.slug == 'float1'
                      "
                    >
                      <question-number
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :precision="1"
                        :errormsg="errormsg"
                      />
                    </template>
                    <template
                      v-else-if="
                        questionList.question.question_type.slug == 'integer'
                      "
                    >
                      <question-number
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :precision="0"
                        :errormsg="errormsg"
                      />
                    </template>
                    <template
                      v-else-if="
                        questionList.question.question_type.slug == 'date'
                      "
                    >
                      <question-date
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :errormsg="errormsg"
                      />
                    </template>
                    <template
                      v-else-if="
                        questionList.question.question_type.slug ==
                        'numeric-slider'
                      "
                    >
                      <question-slider
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :min="questionList.question.options.min_value"
                        :max="questionList.question.options.max_value"
                        :errormsg="errormsg"
                        :pristine="pristine"
                      />
                    </template>

                    <template
                      v-else-if="
                        questionList.question.question_type.slug ==
                        'single-line'
                      "
                    >
                      <question-text
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :max_length="questionList.question.options.max_length"
                        :errormsg="errormsg"
                      />
                    </template>

                    <template
                      v-else-if="
                        questionList.question.question_type.slug == 'textarea'
                      "
                    >
                      <question-text
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :max_length="questionList.question.options.max_length"
                        :multiline="true"
                        :errormsg="errormsg"
                      />
                    </template>
                    <template
                      v-else-if="
                        questionList.question.question_type.slug == 'radio'
                      "
                    >
                      <question-radio
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :options="questionList.question.options.select_options"
                        :textoption="
                          questionList.question_options.open_text_option
                        "
                        :value_extra="questionList.response.value_extra"
                        v-on:submitValue="next"
                        :errormsg="errormsg"
                      />
                    </template>

                    <template
                      v-else-if="
                        questionList.question.question_type.slug == 'sc-icons'
                      "
                    >
                      <question-iconlist
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :errormsg="errormsg"
                        :options="questionList.question.options.select_options"
                        :textoption="
                          questionList.question_options.open_text_option
                        "
                        :value_extra="questionList.response.value_extra"
                        v-on:submitValue="next"
                      >
                      </question-iconlist>
                    </template>

                    <template
                      v-else-if="
                        questionList.question.question_type.slug == 'checkbox'
                      "
                    >
                      <question-checkbox
                        ref="questionObject"
                        :value="value"
                        :valid="!error"
                        :options="questionList.question.options.select_options"
                        :textoption="
                          questionList.question_options.open_text_option
                        "
                        :value_extra="questionList.response.value_extra"
                        :errormsg="errormsg"
                      />
                    </template>
                    <template
                      v-else-if="
                        questionList.question.question_type.slug == 'info'
                      "
                    >
                      <question-info
                        ref="questionObject"
                        :data="questionList.question"
                      />
                    </template>

                    <!--
                      <p
                        v-if="error"
                        class="invalid-feedback show text-center mb-5"
                      >
                        {{ errormsg }}
                      </p>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end question scroll -->
        </div>
      </transition>
    </div>
    <!-- end main scroll area -->

    <div class="navigation-prev-next" :class="{ [bgGradient]: true }">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <template v-if="questionList.question">
              <a
                v-if="questionList.question.allow_back"
                @click="prev"
                class="btn-back float-left"
                >{{ $t('menu_back') }}</a
              >
              <div class="float-right" :class="{ loading: loading }">
                <div class="btn-spinner-wrapper">
                  <lottie-animation
                    path="/lottie/mm-spinner-star.json"
                    id="mm-spinner"
                    :autoplay="true"
                    :loop="true"
                  ></lottie-animation>
                </div>
                <a @click="next" class="btn-next"></a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
///////////////////////////////////////////////////////////////////////////////////////
// QuestionList
// used for Survey and Exp. Sampling
///////////////////////////////////////////////////////////////////////////////////////

import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import store from '@/store'
import QuestionNumber from '@/components/questiontypes/QuestionNumber'
import QuestionDate from '@/components/questiontypes/QuestionDate'
import QuestionSlider from '@/components/questiontypes/QuestionSlider'
import QuestionText from '@/components/questiontypes/QuestionText'
import QuestionIconlist from '@/components/questiontypes/QuestionIconlist'
import QuestionCheckbox from '@/components/questiontypes/QuestionCheckbox'
import QuestionRadio from '@/components/questiontypes/QuestionRadio'
import QuestionInfo from '@/components/questiontypes/QuestionInfo'
import RouteHelper from '@/services/RouteHelper'
import LottieAnimation from '@/components/shared/LottieAnimation'
import UProgressBar from '@/components/shared/UProgressBar'
import EventBus from '@/services/EventBus'

import { App } from '@capacitor/app';
import { Toast } from '@capacitor/toast';

export default {
  store,
  name: 'QuestionList',
  components: {
    QuestionNumber,
    QuestionDate,
    QuestionSlider,
    QuestionText,
    QuestionRadio,
    QuestionCheckbox,
    QuestionIconlist,
    QuestionInfo,
    LottieAnimation,
    UProgressBar
  },
  data() {
    return {
      questionListTitle: '',
      questionListPost: '',
      questionList: undefined,
      questionListPrev: '',
      questionListNext: '',
      questionListFinish: '',

      error: false,
      errormsg: '',
      value: null,
      progressMax: 100,
      progressCur: 33.333333333,
      uniqueKey: 0,
      transitionName: 'next',
      bgGradient: ''
    }
  },
  props: {
    items: [Array, Object],
    samplingData: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState(['researches', 'research', 'survey', 'loading']),
    showQuestionBorder() {
      const questionData = this.questionList.question
      switch (questionData.question_type.slug) {
        case 'checkbox':
        case 'radio':
          return true
        case 'sc-icons':
          return questionData.options.select_options.length > 4
        default:
          return false
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    let startEndPoint = ''
    switch (to.name) {
      case 'sampling':
        startEndPoint = 'samplingStart'
        store.state.needToSeeSamplingCompleted
            = to.params.samplingData.milestoneContributions
        break
      case 'sampling-time':
        startEndPoint = 'samplingTimeStart'
        store.state.needToSeeSamplingCompleted
            = to.params.samplingData.milestoneContributions
        break
    }

    var app = this;
    store
      .dispatch(startEndPoint, to.params)
      .then((response) => {
        next((vm) => {
          switch (to.name) {
            case 'sampling':
            case 'sampling-time':
              vm.questionListTitle = to.params.samplingData.questionListTitle
              vm.questionListPost = 'samplingPost'
              vm.questionListPrev = 'samplingPrev'
              vm.questionListNext = 'samplingNext'
              vm.questionListFinish = 'samplingFinish'
              break
          }

          vm.onLoad(response.data)
        })
      })
      .catch((error) => {
        next((vm) => {
          vm.onError(error)
        })
      })
  },
  methods: {
    ...mapMutations(['setSamplingFinished']),
    getPostData() {
      let postdata = {
        question_list_id: this.questionList.question_list.id,
        data_set_id: this.questionList.data_set.id,
        question_id: this.questionList.question.id,
        value: null,
        value_extra: null
      }

      let questionComponent = this.$refs.questionObject
      if (questionComponent && questionComponent.getValue) {
        postdata.value = questionComponent.getValue()
      } else {
        postdata.value = ''
      }

      if (questionComponent && questionComponent.getValueExtra) {
        postdata.value_extra = questionComponent.getValueExtra()
      }

      return postdata
    },
    onLoad(data) {
      this.uniqueKey++ // needed to refresh v-model bindings

      ///////////////////////////////////////////////////////////////////////////////////////
      // parse question data
      //
      ///////////////////////////////////////////////////////////////////////////////////////
      if (data && data.question && data.question.options) {
        if (data.question.options.min_value) {
          data.question.options.min_value = parseFloat(
            data.question.options.min_value
          )
        }
        if (data.question.options.max_value) {
          data.question.options.max_value = parseFloat(
            data.question.options.max_value
          )
        }
        if (data.question.options.max_length) {
          data.question.options.max_length = parseFloat(
            data.question.options.max_length
          )
        } else {
          data.question.options.max_length = 9999
        }
      }

      this.questionList = data

      // check if value is pristine (for slider)
      if(this.questionList.response) {
        if(
            this.questionList.response.value === undefined ||
            this.questionList.response.value === null ||
            this.questionList.response.value === ''
        ) {
          this.pristine = true
        } else {
          this.pristine = false
        }
      }

      if (
        this.questionList.response &&
        this.questionList.response.value != undefined
      ) {
        this.value = this.questionList.response.value // restore value
      } else if (this.questionList.question.options.min_value != undefined) {
        // set default value for question with min max value
        let min = this.questionList.question.options.min_value
        let amt = this.questionList.question.options.max_value - min
        this.value = min + Math.round(amt / 2)
      } else {
        this.value = null
      }

      this.error = false
      this.errormsg = ''
      store.commit('loading', false)
    },

    ///////////////////////////////////////////////////////////////////////////////////////
    // onError
    //
    ///////////////////////////////////////////////////////////////////////////////////////
    onError(error) {
      if (error && error.response) {
        if (error.response.status && error.response.status === 409) {
          window.location.href = '/research/dashboard'
        }
        Toast.show({
          position: 'bottom',
          duration: 'short',
          text: error.response.data.error_message
        });
      }
      store.commit('loading', false)
    },
    ///////////////////////////////////////////////////////////////////////////////////////
    // prev
    //
    ///////////////////////////////////////////////////////////////////////////////////////
    prev() {
      this.transitionName = 'prev'
      let app = this
      store
        .dispatch(this.questionListPrev, {
          question_list_id: app.questionList.question_list.id,
          data_set_id: app.questionList.data_set.id,
        })
        .then((response) => {
          app.onLoad(response.data)
        })
        .catch((error) => {
          app.onError(error)
        })
    },
    ///////////////////////////////////////////////////////////////////////////////////////
    // next
    //
    ///////////////////////////////////////////////////////////////////////////////////////
    next() {
      this.transitionName = 'next'
      let app = this

      let postData = this.getPostData()

      // post answer to Api
      store
        .dispatch(this.questionListPost, postData)
        .then((response) => {
          if (response.data.has_next === true) {
            // get next question from Api
            store
              .dispatch(this.questionListNext, {
                question_list_id: app.questionList.question_list.id,
                data_set_id: app.questionList.data_set.id,
              })
              .then((response) => {
                app.onLoad(response.data)
              })
              .catch((error) => {
                app.onError(error)
              })
          } else {
            store
              .dispatch(this.questionListFinish, postData)
              .then((response) => {
                store.commit('loading', false)
                // completed, go to next state
                const samplingData = {...this.samplingData, dataSetStatus: 'complete'}
                this.setSamplingFinished(samplingData);
                let route = RouteHelper.getNextSamplingRoute(samplingData)
                if (route) {
                  this.$router.push(route).catch(() => {})
                }
              })
              .catch((error) => {
                store.commit('loading', false)
              })
          }
        })
        .catch((error) => {
          app.onError(error)
        })
    }
  },
  mounted() {
    this.bgGradient = 'gradient-' + this.$route.meta.bg
    let _this = this
    EventBus.$on('submit-value', this.next)
    App.addListener('backButton', function () {
      if (_this.questionList.question.allow_back) {
        _this.prev()
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('submit-value', this.next)
    App.removeAllListeners()
  }
}
</script>
