<template>
  <div :id="id" class="lottie-animation" :class="{'ie11': isIE}"></div>
</template>

<script>

export default {
  name: "lottie-animation",
  props: {
    path: String,
    id: String,
    segments: Array,
    autoplay: Boolean,
    loop: Boolean,
    frame: Number
  },
  watch: {
    frame() {
      if(this.animation && this.frame) {
        this.animation.playSegments([this.frame, this.frame + 1]);
      }
    },
    segments() {
      if(this.animation && this.segments) {     
        this.animation.playSegments(this.segments);
      }
    }
  },
  data() {
    return {
      animation: undefined,
      isIE: false
    }
  },
  mounted() { 

    var app = this;

    this.isIE = (!!window.MSInputMethodContext && !!document.documentMode) || (navigator.userAgent.indexOf('Edge') != -1);

    if(this.path && this.id) 
    {
      this.animation = window.lottie.loadAnimation({
        container: document.getElementById(this.id), // the dom element that will contain the animation
        renderer: this.isIE ? 'canvas' : 'svg',
        loop: this.loop,
        autoplay: this.autoplay,
        path: this.path // the path to the animation json
      });
      this.animation.addEventListener('data_ready', function() {
        if(app.segments && app.segments.length > 0) {
          app.animation.playSegments(app.segments);
        } else if(app.frame) {
          app.animation.playSegments([app.frame - 1, app.frame]);
        }
      });
    }

  },

  destroyed() {
    if(this.animation) {
      this.animation.removeEventListener('data_ready');
      this.animation.destroy();
    }
  }

};
</script>